<template>
  <v-form
      ref="formPersonalDetails"
      v-model="validPersonalDetails"
  >
        <div class="container">
          <v-row>
      <v-col md="4"><v-text-field
        v-model="applicantFirstName"
        :rules="nameRules"
        class="required"
        prepend-icon="mdi-account"
        label="First Name"
        required
      ></v-text-field></v-col>
      <v-col md="4"><v-text-field
        v-model="applicantLastName"
        :rules="nameRules"
        class="required"
        prepend-icon="mdi-account"
        label="Last Name"
        required
      ></v-text-field></v-col>
      <v-col md="4"><v-text-field
        v-model="applicantSurname"
        :rules="nameRules"
        class="required"
        prepend-icon="mdi-account"
        label="Surname"
        required
      ></v-text-field></v-col>
          </v-row>
          <v-text-field
        v-model="fatherName"
        prepend-icon="mdi-account"
        label="Father Name"
        :rules="nameRules"
        class="required"
        required
      ></v-text-field>
      <v-dialog
          persistent
          v-model="officialModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
            name="officialDOB"
            v-model="officialDOB"
            label="offical Date of Birth as in Aadhaar/PAN card"
            slot="activator"
            id="officialDOB"
            prepend-icon="mdi-calendar"
            v-on="on"
            :class="{'required': type}"
            :rules="basicRules"
          ></v-text-field>
          </template>
          <v-date-picker v-model="officialDate" scrollable actions>
            <template v-scope="{ saveTheOfficialDate, cancelTheOfficialDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheOfficialDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheOfficialDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          persistent
          v-model="actualModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
            name="actualDOB"
            v-model="actualDOB"
            label="actual Date of Birth"
            slot="activator"
            id="actualDOB"
            prepend-icon="mdi-calendar"
            v-on="on"
            :class="{'required': type}"
            :rules="basicRules"
          ></v-text-field>
          </template>
          <v-date-picker v-model="actualDate" scrollable actions>
            <template v-scope="{ saveTheActualDate, cancelTheActualDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheActualDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheActualDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
        <v-text-field
        v-model="bloodGroup"
        prepend-icon="mdi-water-plus"
        label="Blood Group"
        :counter="3"
        :rules="bloodGroupRules"
        class="required"
        required
      ></v-text-field>
      <v-text-field
      prepend-icon="mdi-card"
        v-model="aadhaarNumber"
        label="UIDAI NUMBER (AADHAAR)"
        :rules="type ? aadharRules : []"
        :class="{'required': type}"
      ></v-text-field>
      <v-text-field
      prepend-icon="mdi-card"
        v-model="panNumber"
        label="PAN NUMBER"
        :rules="type ? panRules : []"
        :class="{'required': type}"
        required
      ></v-text-field>
      <v-label>Present Address</v-label>
      <v-text-field
        v-model="presentStreet"
        label="Apartment/Door number and Street"
        :rules="basicRules"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentLocality"
        label="Locality"
        :rules="textRules"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentTown"
        label="City/District/Town"
        :rules="textRules"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentBlock"
        label="Sub-District/Block"
        :rules="textRules"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentLandmark"
        :rules="textRules"
        class="required"
        label="Landmark"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentState"
        :rules="textRules"
        class="required"
        label="State"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentPincode"
        :rules="pincodeRules"
        class="required"
        label="Pincode"
        required
      ></v-text-field>
       <v-text-field
        v-model="presentCountry"
        :rules="textRules"
        class="required"
        label="Country"
        required
      ></v-text-field>
      <v-divider></v-divider>
      <v-radio-group v-model="sameAddress">
       <label>Is permanent address same as present address <span class="red--text"><strong>* </strong></span></label>
        <v-radio
          label= "Yes"
          :value= "true"
          v-on:change="updatePermanantAddress"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
          v-on:change="clearPermanantAddress"
        ></v-radio>
      </v-radio-group>
      <label class="active" for="address"> Permanant Address </label>
      <v-text-field
        v-model="permanantStreet"
        label="Apartment/Door number and Street"
        :disabled="sameAddress"
        :rules="basicRules"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantLocality"
        :disabled="sameAddress"
        label="Locality"
        :rules="textRules"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="permanantTown"
        label="City/District/Town"
        :rules="textRules"
        :disabled="sameAddress"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantBlock"
        :disabled="sameAddress"
        label="Sub-District/Block"
        :rules="textRules"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="permanantLandmark"
        :disabled="sameAddress"
        :rules="textRules"
        label="Landmark"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantState"
        :disabled="sameAddress"
        :rules="textRules"
        label="State"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantPincode"
        :disabled="sameAddress"
        :rules="pincodeRules"
        class="required"
        label="Pincode"
        required
      ></v-text-field>
       <v-text-field
        v-model="permanantCountry"
        :disabled="sameAddress"
        :rules="textRules"
        label="Country"
        class="required"
        required
      ></v-text-field>
      <vue-tel-input-vuetify
          mode="international"
          v-model="residenceNumber"
          label="Residency Number"
      ></vue-tel-input-vuetify>
      <vue-tel-input-vuetify
          mode="international"
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="personalNumber"
          :rules="mobRules"
          class="required"
      ></vue-tel-input-vuetify>
      <vue-tel-input-vuetify
          mode="international"
          prepend-icon="mdi-phone"
          label="Official Number"
          v-model="officialNumber"
          :rules="phoneRules"
      ></vue-tel-input-vuetify>
      <v-text-field
       prepend-icon="mdi-email"
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        class="required"
        required
      ></v-text-field>
      <v-data-table
      v-if="langs.length > 0"
      :headers="langHeaders"
      :items="langs"
      :server-items-length="langs.length"
      :items-per-page-options="5"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.lang="props">
            <v-text-field v-model="props.item.lang" :rules="basicRules" ></v-text-field>
      </template>
      <template v-slot:item.read="props">
            <v-checkbox v-model="props.item.read" ></v-checkbox>
      </template>
      <template v-slot:item.write="props">
            <v-checkbox v-model="props.item.write" ></v-checkbox>
      </template>
      <template v-slot:item.speak="props">
            <v-checkbox v-model="props.item.speak" ></v-checkbox>
      </template>
      <template v-if="this.langs.length <= 3" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deleteLRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addLRow"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <v-radio-group v-model="speciallyAbled">
       <label>ARE YOU SPECIALLY-ABLED ? <span class="red--text"><strong>* </strong></span> </label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
    </v-radio-group>
    </div>
    <div v-if="speciallyAbled">
    <label>Please provide details</label>
    <v-textarea rows="2" row-height="18" v-model="speciallyAbledDetails"></v-textarea>
    </div>
    <br>
    <br>
    <br>
     <div>
       <label><b>Profile photo</b> (Passport photo dimensions followed 468x600)<span class="red--text">
         <strong>* </strong></span></label>
       <br>
    <v-card v-if="!fileAdded" width="100%" raised="raised">
    <v-card-title>
        <v-icon color="secondary" left="left">mdi-account-box</v-icon><span>Photo Cropper</span>
    </v-card-title>
    <v-card-text>
        <v-file-input class="my-4" v-model="selectedFile" accept="image/png, image/jpeg"
        label="File" placeholder="Select a file" :show-size="1024" @change="setupCropper"></v-file-input>
        <v-row v-if="objectUrl">
            <v-col class="text-center" cols="12" sm="6">
                <div class="overline">Original</div>
                <div class="image-container elevation-4"><img class="image-preview" ref="source" :src="objectUrl" /></div>
                <div class="d-flex justify-center">
                    <v-btn icon="icon" small="small" @click="resetCropper">
                        <v-icon small="small">mdi-aspect-ratio</v-icon>
                    </v-btn>
                    <div class="mx-2"></div>
                    <v-btn icon="icon" small="small" @click="rotateLeft">
                        <v-icon small="small">mdi-rotate-left</v-icon>
                    </v-btn>
                    <v-btn icon="icon" small="small" @click="rotateRight">
                        <v-icon small="small">mdi-rotate-right</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col class="text-center" cols="12" sm="6">
                <div class="overline">Preview</div>
                <div class="image-container elevation-4"><img class="image-preview" :src="previewCropped" /></div>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!objectUrl" @click="fileAdded = true;upload();">
            <v-icon left="left">mdi-send</v-icon><span>Submit</span>
        </v-btn>
    </v-card-actions>
</v-card>
    <v-progress-circular
      v-if="uploading && !uploadEnd"
      :size="100"
      :width="15"
      :rotate="360"
      :value="progressUpload"
      color="primary">
      {{ progressUpload }}%
    </v-progress-circular>
    <h3
      v-if="uploadEnd || fileAdded"
     >Image uploaded</h3>
     <v-img
      v-if="fileAdded"
      :src="this.previewCropped"
      width="25%"/>
      <br>
      <v-img
      v-if="!fileAdded && this.downloadURL!='' "
      :src="this.downloadURL"
      width="25%"/>
      <br>
    <div v-if="uploadEnd || fileAdded">
      <v-btn
        class="ma-0"
        dark
        small
        color="error"
        @click="fileAdded=false;deleteImage();"
        >
        Delete
      </v-btn>
    </div>
    <br>
    <br>
    <br>
    <!-- <v-btn
      @click.native="selectFile"
      v-show="fileAdded">
        Upload Profile Photo
        <v-icon
        right
        aria-hidden="true">
          mdi-image-plus
        </v-icon>
    </v-btn> -->
  </div>
        <v-btn class="mr-4" color="primary" @click="validatePersonalDetails"
          :disabled="loading"
          :loading="loading">
      Continue
    </v-btn>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
          </div>
          </v-form>
</template>
<style scoped>
.image-container {
  display: inline-block; }

.image-preview {
  display: block;
  max-height: 229px;
  max-width: 100%; }
</style>
<script>
import moment from 'moment';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import debounce from 'lodash/debounce';

export default {
  props: { currentStep: Number },
  data: () => ({
    validPersonalDetails: true,
    sameAddress: false,
    loading: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    aadharRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 12) || 'Aadhaar number must be 12 digits',
    ],
    panRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z0-9]/gi.test(v) || 'Field only accepts numbers and text',
      (v) => (v && v.length === 10) || 'PAN  must be 10 characters',
      (v) => /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(v) || 'please enter valid PAN',
    ],
    bloodGroupRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 3) || 'Invalid Blood Group, Field must be maximum 3 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    pincodeRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 6) || 'Please enter valid pincode',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    submittedId: '',
    applicantFirstName: '',
    applicantLastName: '',
    applicantSurname: '',
    fatherName: '',
    martialStatus: false,
    bloodGroup: '',
    aadhaarNumber: '',
    panNumber: '',
    presentStreet: '',
    presentLocality: '',
    presentTown: '',
    presentLandmark: '',
    presentState: '',
    presentPincode: '',
    presentCountry: '',
    presentBlock: '',
    permanantBlock: '',
    permanantStreet: '',
    permanantLocality: '',
    permanantTown: '',
    permanantLandmark: '',
    permanantState: '',
    permanantPincode: '',
    permanantCountry: '',
    residenceNumber: '',
    personalNumber: '',
    officialNumber: '',
    speciallyAbled: false,
    notSpeciallyAbled: false,
    speciallyAbledDetails: false,
    error: {
      status: false,
      message: '',
    },
    imgHeight: 0,
    imgWidth: 0,
    langHeaders: [
      {
        text: 'Language',
        value: 'lang',
        align: 'left',
        sortable: false,
      },
      { text: 'Read', value: 'read' },
      { text: 'Write', value: 'write' },
      { text: 'Speak', value: 'speak' },
      { text: 'Actions', value: 'action' },
    ],
    langs: [],
    progressUpload: 0,
    fileName: '',
    cropper: null,
    objectUrl: null,
    previewCropped: null,
    selectedFile: null,
    uploadTask: '',
    uploading: false,
    uploadEnd: false,
    downloadURL: '',
    fileAdded: false,
    firestorage: '',
    type: false,
    refKey: '',
    fileList: null,
    officialDate: '',
    officialModal: false,
    officialDOB: '',
    actualDate: '',
    actualModal: false,
    actualDOB: '',
    currentDate: moment(new Date()),
  }),
  methods: {
    inputHandler(date) {
      let dt = date;
      if (typeof dt === 'string') {
        dt = moment(dt).toDate();
      }
      this.isActive = false;
      this.theDate = dt;
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      // console.log('Dialog closed');
    },
    cancelTheOfficialDate() {
      this.officialModal = false;
    },
    cancelTheActualDate() {
      this.actualModal = false;
    },
    saveTheOfficialDate() {
      this.officialModal = false;
      this.officialDOB = this.formatedDate(this.officialDate);
    },
    saveTheActualDate() {
      this.actualModal = false;
      this.actualDOB = this.formatedDate(this.actualDate);
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    updatedTask() {
      // console.log('hi');
    },
    // upload photo methods
    selectFile() {
      this.$refs.uploadInput.click();
    },
    // start
    resetCropper() {
      this.cropper.reset();
    },
    rotateLeft() {
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    },
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy();
      }
      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }
      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }
      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance); // what is this
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.source, {
        aspectRatio: 0.78,
        maxWidth: 468,
        maxHeight: 600,
        cropBoxResizable: false,
        crop: this.debouncedUpdatePreview,
      });
    },
    updatePreview() {
      // console.log(event);
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/jpeg');
    },
    // end
    // async detectFiles(e) {
    //   this.firestorage = this.firebase.storage();
    //   this.fileList = e.target.files || e.dataTransfer.files;
    //   Array.from(Array(this.fileList.length).keys()).map((x) => {
    //     const optimizedImage = sharp(this.fileList[x]).resize(600, 468).jpeg();
    //     return null;
    //   });
    // },
    upload() {
      // this.$refs.form.reset();
      const canvas = this.cropper.getCroppedCanvas();
      const rawContent = canvas.toDataURL('image/jpeg', 0.5);
      const fileContent = rawContent.replace(/^data:image\/[a-z]+;base64,/, '');
      // const file = this.dataURItoBlob(fileContent);
      this.firestorage = this.firebase.app().storage('gs://manipal-pra-original-images');
      this.fileName = `${this.applicantFirstName}${this.applicantLastName}${this.personalNumber.substring(9)}.jpeg`.replace(/\s/g, '');
      this.uploading = true;
      // let storageLink = 'images/';
      // storageLink += this.fileName;
      // console.log(this.fileName);
      // this.uploadTask = this.firestorage.ref(storageLink).put(file);
      this.uploadTask = this.firestorage.ref().child(this.fileName).putString(fileContent, 'base64', { contentType: 'image/jpeg' });
    },
    deleteImage() {
      this.firestorage = this.firebase.storage();
      let storageLink = 'images/';
      storageLink += `${this.applicantFirstName}${this.applicantLastName}${this.personalNumber.substring(9)}.jpeg`.replace(/\s/g, '');
      this.firestorage.ref(storageLink).delete().then(() => {
        this.uploading = false;
        this.uploadEnd = false;
        this.downloadURL = '';
        this.fileAdded = false;
      })
        .catch((error) => {
          // console.error(`file delete error occured: ${error}`);
          this.error.message = error;
        });
      // this.$refs.form.reset();
    },
    addLRow() {
      const newRow = {
        lang: '',
        read: '',
        write: '',
        speak: '',
      };
      this.langs.push(newRow);
    },
    deleteLRow(index) {
      this.langs.splice(index, 1);
    },
    validatePersonalDetails() {
      if (this.$refs.formPersonalDetails.validate()) {
        if (this.downloadURL !== '' && this.downloadURL !== undefined) {
          // console.log('entered submission');
          this.submitPersonalDetails();
        } else {
          this.error.message = 'Please upload image';
          this.error.status = true;
        }
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    updatePermanantAddress() {
      this.permanantStreet = this.presentStreet;
      this.permanantLocality = this.presentLocality;
      this.permanantTown = this.presentTown;
      this.permanantLandmark = this.presentLandmark;
      this.permanantState = this.presentState;
      this.permanantPincode = this.presentPincode;
      this.permanantCountry = this.presentCountry;
      this.permanantBlock = this.presentBlock;
    },
    clearPermanantAddress() {
      this.permanantStreet = '';
      this.permanantLocality = '';
      this.permanantTown = '';
      this.permanantLandmark = '';
      this.permanantState = '';
      this.permanantPincode = '';
      this.permanantCountry = '';
      this.permanantBlock = '';
    },
    async singleEmit() {
      this.$emit('update:currentStep', this.currentStep + 1);
      if (this.refKey === '' || this.refKey === undefined) {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          formData: this.submittedId,
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      }
    },
    async submitPersonalDetails() {
      const presentDict = {
        street: this.presentStreet,
        locality: this.presentLocality,
        city: this.presentTown,
        landmark: this.presentLandmark,
        state: this.presentState,
        pincode: this.presentPincode,
        country: this.presentCountry,
        block: this.presentBlock,
      };
      // console.log(presentDict);
      const permanantDict = {
        street: this.permanantStreet,
        locality: this.permanantLocality,
        city: this.permanantTown,
        landmark: this.permanantLandmark,
        state: this.permanantState,
        pincode: this.permanantPincode,
        country: this.permanantCountry,
        block: this.permanantBlock,
      };
      // console.log(permanantDict);
      // here we will send personal details  of the applicant to firestore
      this.loading = true;
      try {
        if (this.refKey === undefined || this.refKey === '') {
          const res = await this.firebase.firestore().collection('formData').add({
            applicantFirstName: this.applicantFirstName,
            applicantLastName: this.applicantLastName,
            applicantSurname: this.applicantSurname,
            fatherName: this.fatherName,
            aadhaarNumber: this.aadhaarNumber,
            bloodGroup: this.bloodGroup,
            communicationLanguages: this.langs,
            email: this.email,
            martialStatus: this.martialStatus,
            officialNumber: this.officialNumber,
            panNumber: this.panNumber,
            permanantAddres: permanantDict,
            personalNumber: this.personalNumber,
            presentAddress: presentDict,
            residenceNumber: this.residenceNumber,
            speciallyAbled: this.speciallyAbled,
            speciallyAbledDetails: this.speciallyAbledDetails,
            officialDOB: this.officialDOB,
            actualDOB: this.actualDOB,
          });
          this.loading = false;
          // console.log('1', res);
          this.submittedId = res.id;
          this.singleEmit();
        } else {
          await this.firebase.firestore().collection('formData').doc(this.refKey).update({
            applicantFirstName: this.applicantFirstName,
            applicantLastName: this.applicantLastName,
            applicantSurname: this.applicantSurname,
            fatherName: this.fatherName,
            aadhaarNumber: this.aadhaarNumber,
            bloodGroup: this.bloodGroup,
            communicationLanguages: this.langs,
            email: this.email,
            martialStatus: this.martialStatus,
            officialNumber: this.officialNumber,
            panNumber: this.panNumber,
            permanantAddres: permanantDict,
            personalNumber: this.personalNumber,
            presentAddress: presentDict,
            residenceNumber: this.residenceNumber,
            speciallyAbled: this.speciallyAbled,
            speciallyAbledDetails: this.speciallyAbledDetails,
            officialDOB: this.officialDOB,
            actualDOB: this.actualDOB,
          });
          this.loading = false;
          this.singleEmit();
        }
      } catch (error) {
        // console.log(error);
        this.error.message = error;
      }
    },
    async getType() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey);
      const doc = await type.get();
      const info = doc.data();
      this.type = info.postRecruitment;
      this.refKey = info.formData;
    },
    async getPersonalDetails() {
      const personalDetails = await this.firebase.firestore().collection('formData').doc(this.refKey);
      const doc = await personalDetails.get();
      const info = doc.data();
      this.applicantFirstName = info.applicantFirstName;
      this.applicantLastName = info.applicantLastName;
      this.applicantSurname = info.applicantSurname;
      this.fatherName = info.fatherName;
      this.aadhaarNumber = info.aadhaarNumber;
      this.bloodGroup = info.bloodGroup;
      this.langs = info.communicationLanguages;
      this.email = info.email;
      this.martialStatus = info.martialStatus;
      this.officialNumber = info.officialNumber;
      this.panNumber = info.panNumber;
      this.permanantAddres = info.permanantAddres;
      this.personalNumber = info.personalNumber;
      this.presentAddress = info.presentAddress;
      this.residenceNumber = info.residenceNumber;
      this.speciallyAbled = info.speciallyAbled;
      this.speciallyAbledDetails = info.speciallyAbledDetails;
      const permanantDict = info.permanantAddres;
      const presentDict = info.presentAddress;
      this.permanantStreet = permanantDict.street;
      this.permanantLocality = permanantDict.locality;
      this.permanantTown = permanantDict.city;
      this.permanantBlock = permanantDict.block;
      this.permanantLandmark = permanantDict.landmark;
      this.permanantState = permanantDict.state;
      this.permanantPincode = permanantDict.pincode;
      this.permanantCountry = permanantDict.country;
      this.presentStreet = presentDict.street;
      this.presentLocality = presentDict.locality;
      this.presentTown = presentDict.city;
      this.presentLandmark = presentDict.landmark;
      this.presentBlock = presentDict.block;
      this.presentState = presentDict.state;
      this.presentPincode = presentDict.pincode;
      this.presentCountry = presentDict.country;
      this.officialDOB = info.officialDOB;
      this.actualDOB = info.actualDOB;
      if (this.langs.length < 3) {
        this.addLRow();
        this.addLRow();
        this.addLRow();
      }
      this.downloadURL = await this.firebase.storage().ref()
        .child(`images/${this.applicantFirstName}${this.applicantLastName}${this.personalNumber.substring(9)}.jpeg`.replace(/\s/g, '')).getDownloadURL();
    },
  },
  watch: {
    uploadTask() {
      this.uploadTask.on('state_changed', (sp) => {
        this.progressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100);
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.uploadEnd = true;
          this.downloadURL = downloadURL;
          this.$emit('downloadURL', downloadURL);
        });
      });
    },
    refKey() {
      if (this.currentStep === 1) {
        if (this.refKey !== undefined) {
          if (this.refKey !== '') {
            this.getPersonalDetails();
          }
        } else {
          this.addLRow();
          this.addLRow();
          this.addLRow();
        }
      }
    },
    downloadURL() {
      if (this.downloadURL !== '' && this.downloadURL !== null && this.downloadURL !== undefined) {
        // console.log('this is download url', this.downloadURL);
        this.fileAdded = true;
      } else {
        this.fileAdded = false;
      }
    },
  },
  mounted() {
    this.getType();
  },
  computed: {
    dockey() {
      return this.$store.state.user.currentUser.email;
    },
    debouncedUpdatePreview() {
      return debounce(this.updatePreview, 257);
    },
  },
};
</script>
