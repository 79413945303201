<template>
<v-form
          ref="formReferencesDetails"
          v-model="validReferenceDetails"
        >
        <div class="containter">
          <label>First Reference</label>
        <v-text-field
        v-model="firstRefName"
        :counter="100"
        prepend-icon="mdi-account"
        label="Name"
        :rules="type ? nameRules : []"
          :class="{'required': type}"
        required
      ></v-text-field>
    <v-label>Address</v-label>
    <v-text-field
        v-model="firstRefStreet"
        label="Street"
        :rules="type ? basicRules : []"
        :class="{'required': type}"
      ></v-text-field>
    <v-text-field
        v-model="firstRefLocality"
        label="Locality"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
    <v-text-field
        v-model="firstRefTown"
        label="City/District/Town"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
      <v-text-field
        v-model="firstRefBlock"
        label="Sub-District/Block"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
    <v-text-field
        v-model="firstRefLandmark"
        label="Landmark"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
      <v-text-field
        v-model="firstRefState"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
        label="State"
      ></v-text-field>
      <v-text-field
        v-model="firstRefPincode"
        :rules="type ? pincodeRules : []"
          :class="{'required': type}"
        label="Pincode"
      ></v-text-field>
       <v-text-field
        v-model="firstRefCountry"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
        label="Country"
      ></v-text-field>
<vue-tel-input-vuetify
          mode="international"
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="firstRefPhoneNumber"
          :class="{'required': type}"
          :rules="type ? mobRules : []"
      ></vue-tel-input-vuetify>
<v-text-field
       prepend-icon="mdi-email"
        v-model="firstRefEmail"
        label="E-mail"
        :rules="type ? emailRules : []"
        :class="{'required': type}"
        required
      ></v-text-field>
<div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <label >Association <span class="red--text" v-if="type"><strong> *</strong></span></label>
    <v-checkbox
        v-model="firstRefAssociationClient"
        :label="`Client/Customer`"
      ></v-checkbox>
      <v-checkbox
        v-model="firstRefAssociationProfessional"
        :label="`Professional Contact`"
      ></v-checkbox>
      <v-checkbox
        v-model="firstRefAssociationColleague"
        :label="`Former Colleague/Manager/Employer`"
      ></v-checkbox>
    </div>
    <v-divider>
    </v-divider>
    <label>Second Reference</label>
        <v-text-field
        v-model="secondRefName"
        :counter="100"
        prepend-icon="mdi-account"
        label="Name"
        :rules="type ? nameRules : []"
        :class="{'required': type}"
        required
      ></v-text-field>
<v-label>Address</v-label>
<v-text-field
        v-model="secondRefStreet"
          :class="{'required': type}"
        label="Street"
      :rules="type ? basicRules : []"
      ></v-text-field>
    <v-text-field
        v-model="secondRefLocality"
          :class="{'required': type}"
        label="Locality"
      :rules="type ? basicRules : []"
      ></v-text-field>
    <v-text-field
        v-model="secondRefTown"
        label="City/District/Town"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
      <v-text-field
        v-model="secondRefBlock"
        label="Sub-District/Block"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
      ></v-text-field>
    <v-text-field
        v-model="secondRefLandmark"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
        label="Landmark"
      ></v-text-field>
      <v-text-field
        v-model="secondRefState"
          :class="{'required': type}"
      :rules="type ? basicRules : []"
        label="State"
      ></v-text-field>
      <v-text-field
        v-model="secondRefPincode"
          :class="{'required': type}"
        :rules="type ? pincodeRules : []"
        label="Pincode"
      ></v-text-field>
       <v-text-field
        v-model="secondRefCountry"
      :rules="type ? basicRules : []"
          :class="{'required': type}"
        label="Country"
      ></v-text-field>
<vue-tel-input-vuetify
          mode="international"
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="secondRefPhoneNumber"
          :rules="type ? mobRules : []"
          :class="{'required': type}"
      ></vue-tel-input-vuetify>
<v-text-field
       prepend-icon="mdi-email"
        v-model="secondRefEmail"
        label="E-mail"
        :rules="type ? emailRules : []"
        :class="{'required': type}"
        required
      ></v-text-field>
<div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <label >Association <span class="red--text" v-if="type"><strong> *</strong></span></label>
    <v-checkbox
        v-model="secondRefAssociationClient"
        :label="`Client/Customer`"
      ></v-checkbox>
      <v-checkbox
        v-model="secondRefAssociationProfessional"
        :label="`Professional Contact`"
      ></v-checkbox>
      <v-checkbox
        v-model="secondRefAssociationColleague"
        :label="`Former Colleage/Manager/Employer`"
      ></v-checkbox>
    </div>
    <br>
    <br>
    <form ref="form" v-show="!fileAdded && downloadURL === ''">
      <label><b><h3>Upload Latest Resume </h3></b>(only .pdf,.doc and .docx format allowed, maximum file size is 2 Mb)
      <span class="red--text"><strong> *</strong></span></label><br>
      <input
        id="files"
        type="file"
        name="file"
        ref="uploadInput"
        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        :multiple="false"
        @change="detectFiles($event)" />
    </form>
    <v-progress-circular
      v-if="uploading && !uploadEnd"
      :size="100"
      :width="15"
      :rotate="360"
      :value="progressUpload"
      color="primary">
      {{ progressUpload }}%
    </v-progress-circular>
    <label v-if="uploadEnd || fileAdded"> {{this.fileName}} Uploaded</label>
    <div v-if="uploadEnd || fileAdded || downloadURL !== ''">
      <v-btn v-if="downloadURL != '' && downloadURL != undefined"
   class="ma-2"
   outlined
   :href="downloadURL"
   download>
       View Resume
      </v-btn>
      <v-btn
        class="ma-0"
        dark
        small
        color="error"
        @click="fileAdded=false;deleteDoc();"
        >
        Delete
      </v-btn>
    </div>
    <br>
    <br>
    <v-divider></v-divider>
    <v-checkbox
        v-model="finalAgreement"
        :label="`I, the above named applicant do hereby solemnly declare that the above information is
        correct to the best of my knowledge and belief.
        In case any particulars are found to be incorrect,
        it will be open to the management to dispense with my services without any notice. *`"
        required
      ></v-checkbox>
      <v-card>
        <v-card-actions class="justify-space-between">
            <v-btn class="mr-4" color="primary" @click="$emit('update:currentStep', currentStep -1);">
      Back
    </v-btn>
        <v-btn class="mr-4"
          color="primary"
          :disabled="loading || !finalAgreement || !fileAdded" :loading="loading"
          @click="validateReferencesDetails();"
        >
          Next
        </v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </div>
            </v-form>
</template>

<script>
const axios = require('axios');

export default {
  props: { currentStep: Number },
  data: () => ({
    loading: false,
    validReferenceDetails: true,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    pincodeRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 6) || 'Please enter valid pincode',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    submittedId: '',
    firstRefAssociationClient: false,
    firstRefAssociationProfessional: false,
    firstRefAssociationColleague: true,
    secondRefAssociationClient: false,
    secondRefAssociationProfessional: false,
    secondRefAssociationColleague: true,
    finalAgreement: false,
    firstRefName: '',
    firstRefStreet: '',
    firstRefLocality: '',
    firstRefTown: '',
    firstRefLandmark: '',
    firstRefState: '',
    firstRefPincode: '',
    firstRefCountry: '',
    firstRefPhoneNumber: '',
    firstRefEmail: '',
    secondRefName: '',
    firstRefBlock: '',
    secondRefBlock: '',
    secondRefStreet: '',
    secondRefLocality: '',
    secondRefTown: '',
    secondRefLandmark: '',
    secondRefState: '',
    secondRefPincode: '',
    secondRefCountry: '',
    secondRefPhoneNumber: '',
    secondRefEmail: '',
    progressUpload: 0,
    fileName: '',
    uploadTask: '',
    uploading: false,
    uploadEnd: false,
    downloadURL: '',
    fileAdded: false,
    firestorage: '',
    applicantName: '',
    createdBy: '',
    error: {
      status: false,
      message: '',
    },
    success: {
      status: false,
      message: '',
    },
    refKey: '',
    type: false,
  }),
  methods: {
    validateReferencesDetails() {
      if (this.$refs.formReferencesDetails.validate()) {
        this.submitReferenceDetails();
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    async submitReferenceDetails() {
      this.loading = true;
      if (this.firstRefPhoneNumber === undefined) {
        this.firstRefPhoneNumber = '';
      }
      if (this.secondRefPhoneNumber === undefined) {
        this.secondRefPhoneNumber = '';
      }
      try {
        if (this.refKey === '' || this.refKey === undefined) {
          const res = await this.firebase.firestore().collection('referenceDetails').add({
            firstRefAssociationClient: this.firstRefAssociationClient,
            firstRefAssociationProfessional: this.firstRefAssociationProfessional,
            firstRefAssociationColleague: this.firstRefAssociationColleague,
            secondRefAssociationClient: this.secondRefAssociationClient,
            secondRefAssociationProfessional: this.secondRefAssociationProfessional,
            secondRefAssociationColleague: this.secondRefAssociationColleague,
            finalAgreement: this.finalAgreement,
            firstRefName: this.firstRefName,
            firstRefStreet: this.firstRefStreet,
            firstRefLocality: this.firstRefLocality,
            firstRefTown: this.firstRefTown,
            firstRefLandmark: this.firstRefLandmark,
            firstRefState: this.firstRefState,
            firstRefPincode: this.firstRefPincode,
            firstRefCountry: this.firstRefCountry,
            firstRefPhoneNumber: this.firstRefPhoneNumber,
            firstRefEmail: this.firstRefEmail,
            firstRefBlock: this.firstRefBlock,
            secondRefBlock: this.secondRefBlock,
            secondRefName: this.secondRefName,
            secondRefStreet: this.secondRefStreet,
            secondRefLocality: this.secondRefLocality,
            secondRefTown: this.secondRefTown,
            secondRefLandmark: this.secondRefLandmark,
            secondRefState: this.secondRefState,
            secondRefPincode: this.secondRefPincode,
            secondRefCountry: this.secondRefCountry,
            secondRefPhoneNumber: this.secondRefPhoneNumber,
            secondRefEmail: this.secondRefEmail,
            docLink: this.downloadURL,
            docName: this.fileName,
          });
          this.submittedId = res.id;
          this.submitSectionId();
        } else {
          await this.firebase.firestore().collection('referenceDetails').doc(this.refKey).update({
            firstRefAssociationClient: this.firstRefAssociationClient,
            firstRefAssociationProfessional: this.firstRefAssociationProfessional,
            firstRefAssociationColleague: this.firstRefAssociationColleague,
            secondRefAssociationClient: this.secondRefAssociationClient,
            secondRefAssociationProfessional: this.secondRefAssociationProfessional,
            secondRefAssociationColleague: this.secondRefAssociationColleague,
            finalAgreement: this.finalAgreement,
            firstRefName: this.firstRefName,
            firstRefStreet: this.firstRefStreet,
            firstRefLocality: this.firstRefLocality,
            firstRefTown: this.firstRefTown,
            firstRefLandmark: this.firstRefLandmark,
            firstRefState: this.firstRefState,
            firstRefPincode: this.firstRefPincode,
            firstRefCountry: this.firstRefCountry,
            firstRefPhoneNumber: this.firstRefPhoneNumber,
            firstRefEmail: this.firstRefEmail,
            firstRefBlock: this.firstRefBlock,
            secondRefBlock: this.secondRefBlock,
            secondRefName: this.secondRefName,
            secondRefStreet: this.secondRefStreet,
            secondRefLocality: this.secondRefLocality,
            secondRefTown: this.secondRefTown,
            secondRefLandmark: this.secondRefLandmark,
            secondRefState: this.secondRefState,
            secondRefPincode: this.secondRefPincode,
            secondRefCountry: this.secondRefCountry,
            secondRefPhoneNumber: this.secondRefPhoneNumber,
            secondRefEmail: this.secondRefEmail,
            docLink: this.downloadURL,
            docName: this.fileName,
          });
          this.submitSectionId();
        }
      } catch (error) {
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
        this.loading = false;
      }
    },
    async getReferencesDetails() {
      const referencesDetails = await this.firebase.firestore().collection('referenceDetails').doc(this.refKey);
      const doc = await referencesDetails.get();
      const info = doc.data();
      this.firstRefAssociationClient = info.firstRefAssociationClient;
      this.firstRefAssociationProfessional = info.firstRefAssociationProfessional;
      this.firstRefAssociationColleague = info.firstRefAssociationColleague;
      this.secondRefAssociationClient = info.secondRefAssociationClient;
      this.secondRefAssociationProfessional = info.secondRefAssociationProfessional;
      this.secondRefAssociationColleague = info.secondRefAssociationColleague;
      this.finalAgreement = info.finalAgreement;
      this.firstRefName = info.firstRefName;
      this.firstRefStreet = info.firstRefStreet;
      this.firstRefLocality = info.firstRefLocality;
      this.firstRefTown = info.firstRefTown;
      this.firstRefLandmark = info.firstRefLandmark;
      this.firstRefState = info.firstRefState;
      this.firstRefPincode = info.firstRefPincode;
      this.firstRefCountry = info.firstRefCountry;
      this.firstRefPhoneNumber = info.firstRefPhoneNumber;
      this.firstRefEmail = info.firstRefEmail;
      this.firstRefBlock = info.firstRefBlock;
      this.secondRefBlock = info.secondRefBlock;
      this.secondRefName = info.secondRefName;
      this.secondRefStreet = info.secondRefStreet;
      this.secondRefLocality = info.secondRefLocality;
      this.secondRefTown = info.secondRefTown;
      this.secondRefLandmark = info.secondRefLandmark;
      this.secondRefState = info.secondRefState;
      this.secondRefPincode = info.secondRefPincode;
      this.secondRefCountry = info.secondRefCountry;
      this.secondRefPhoneNumber = info.secondRefPhoneNumber;
      this.secondRefEmail = info.secondRefEmail;
      this.downloadURL = info.docLink;
      this.fileName = info.docName;
      if (this.downloadURL !== '') {
        this.fileAdded = true;
      }
      // console.log(this.fileAdded, 'what hte hell');
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.email);
      const rawData = await type.get();
      const info = rawData.data();
      this.type = info.postRecruitment;
      this.refKey = info.referenceDetails;
      this.name = info.name;
      // console.log('getting type', this.refKey);
    },
    async submitSectionId() {
      if (this.refKey === '' || this.refKey === undefined) {
        try {
          await this.firebase.firestore().collection('allSectionsIDmap').doc(this.email).set({
            referenceDetails: this.submittedId,
            updatedStep: this.currentStep + 1,
          }, { merge: true });
          this.loading = false;
          this.notifyHRThroughMail();
          this.notifyCandidateThroughMail();
          this.$emit('update:currentStep', this.currentStep + 1);
        } catch (error) {
          if (error) {
            this.error.message = 'Something went wrong. Please try in some time.';
            this.error.status = true;
          }
          this.loading = false;
        }
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.email).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
        this.loading = false;
        this.notifyHRThroughMail();
        this.notifyCandidateThroughMail();
        this.$emit('update:currentStep', this.currentStep + 1);
      }
    },
    async notifyHRThroughMail() {
      const dataToSend = {
        email: this.createdBy,
        name: this.applicantName,
        to: 'hr',
        type: this.type,
      };
      const bufferObj = Buffer.from(JSON.stringify(dataToSend), 'utf8');
      // Encode the Buffer as a base64 string
      const encodedData = bufferObj.toString('base64');
      const responseAx = await axios({
        // praform-a3e92
        url: 'https://us-central1-manipal-pra-app.cloudfunctions.net/notifyMail',
        data: {
          payload: encodedData,
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      });
      if (responseAx.data === 'notifying mail Sended') {
        this.success.message = 'Notification successful';
        this.success.status = true;
      } else {
        this.success.message = 'Notification unsuccessful';
        this.success.status = true;
      }
    },
    async notifyCandidateThroughMail() {
      const dataToSend = {
        email: this.$store.state.user.currentUser.email,
        name: this.applicantName,
        to: 'candidate',
        type: this.type,
      };
      const bufferObj = Buffer.from(JSON.stringify(dataToSend), 'utf8');
      // Encode the Buffer as a base64 string
      const encodedData = bufferObj.toString('base64');
      const responseAx = await axios({
        // praform-a3e92
        url: 'https://us-central1-manipal-pra-app.cloudfunctions.net/notifyMail',
        data: {
          payload: encodedData,
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      });
      if (responseAx.data === 'notifying mail Sended') {
        this.success.message = 'Notification successful';
        this.success.status = true;
      } else {
        this.success.message = 'Notification unsuccessful';
        this.success.status = true;
      }
    },
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      this.firestorage = this.firebase.storage();
      const fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        if (fileList[x].size <= (1024 * 1024 * 2)) {
          if (fileList[x].type === 'application/pdf' || fileList[x].type === 'application/msword'
          || fileList[x].type
          === 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.fileAdded = true;
            this.upload(fileList[x]);
          } else {
            this.$refs.form.reset();
            this.error.status = true;
            this.error.message = 'Please upload a valid document file';
          }
        } else {
          this.$refs.form.reset();
          this.error.status = true;
          this.error.message = 'Please upload a file less than 2 Mb ';
        }
        return null;
      });
    },
    upload(file) {
      this.firestorage = this.firebase.storage();
      // console.log(this.applicantName, 'fetched applicant name');
      let firstHalf = `${this.applicantName}Resume`;
      firstHalf = firstHalf.split(' ').join('');
      // console.log(firstHalf, 'firstHalf');
      let secondHalf = '';
      if (file.type === 'application/pdf') {
        secondHalf = '.pdf';
      } else if (file.type === 'application/msword') {
        secondHalf = '.doc';
      } else {
        secondHalf = '.docx';
      }
      this.fileName = `${firstHalf}${secondHalf}`;
      // console.log(this.fileName);
      this.uploading = true;
      let storageLink = 'docs/';
      storageLink += this.fileName;
      this.uploadTask = this.firestorage.ref(storageLink).put(file);
      this.$refs.form.reset();
    },
    deleteDoc() {
      this.firestorage = this.firebase.storage();
      let storageLink = 'docs/';
      storageLink += this.fileName;
      this.firestorage.ref(storageLink).delete().then(() => {
        this.uploading = false;
        this.uploadEnd = false;
        this.downloadURL = '';
        this.fileAdded = false;
        if (this.refKey !== undefined && this.refKey !== '') {
          this.deleteDocFromFireStore();
        }
      })
        .catch((error) => {
          console.error(`file delete error occured: ${error}`);
        });
      this.$refs.form.reset();
    },
    async deleteDocFromFireStore() {
      await this.firebase.firestore().collection('referenceDetails').doc(this.refKey).update({
        docLink: '',
        docName: '',
      });
    },
    async updateDocToFireStore() {
      await this.firebase.firestore().collection('referenceDetails').doc(this.refKey).update({
        docLink: this.downloadURL,
        docName: this.fileName,
      });
    },
    async getName() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('usersList').doc(this.$store.state.user.currentUser.email);
      const doc = await type.get();
      const info = doc.data();
      this.applicantName = info.name;
      this.createdBy = info.createdBy;
      // console.log(this.$store.state.user.currentUser.formType, 'fetch at index level');
    },
  },
  computed: {
    name: {
      get() {
        if (this.$store.state.user.currentUser.name === null) {
          return this.$store.state.user.currentUser.email;
        }
        return this.$store.state.user.currentUser.name;
      },
      set(newName) {
        return newName;
      },
    },
    email() {
      return this.$store.state.user.currentUser.email;
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getReferencesDetails();
        }
      }
    },
    uploadTask() {
      this.uploadTask.on('state_changed', (sp) => {
        this.progressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100);
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.uploadEnd = true;
          this.downloadURL = downloadURL;
          this.$emit('downloadURL', downloadURL);
          if (this.refKey !== undefined) {
            this.updateDocToFireStore();
          }
        });
      });
    },
  },
  mounted() {
    this.getRefKey();
    this.getName();
  },
};
</script>
