<template>
  <v-main>
    <v-container>
  <div>
      <v-stepper v-model="myPayLoadData.currentStep" vertical>
        <div v-for="(step, i) in steps" :key="i">
          <v-stepper-step
            :complete="1 > totalSteps + 1"
            :step="step.count"
          >
            {{step.name}}
          </v-stepper-step>
          <v-stepper-content :step="step.count">
            <!-- you must show the desired component -->
            <component :is="step.component" v-bind.sync="getProps()"></component>
          </v-stepper-content>
        </div>
      </v-stepper>
      <v-btn v-if="myPayLoadData.currentStep === 7" @click="goToDisplayForm" color="success">View Application</v-btn>
  </div>
    </v-container>
  </v-main>
</template>

<script>
import personalDetails from '../../../components/personalDetails.vue';
import familyDetails from '../../../components/familyDetails.vue';
import academicDetails from '../../../components/academicDetails.vue';
import experienceDetails from '../../../components/experienceDetails.vue';
import remunerationDetails from '../../../components/remunerationDetails.vue';
import referencesDetails from '../../../components/referencesDetails.vue';
import completed from '../../../components/comleted.vue';

export default {
  name: 'mainform',
  components: {
    personalDetails,
    familyDetails,
    academicDetails,
    experienceDetails,
    remunerationDetails,
    referencesDetails,
    completed,
  },
  data() {
    return {
      myPayLoadData: {
        currentStep: 1,
      },
    };
  },
  methods: {
    getProps() {
      return this.myPayLoadData;
    },
    goToDisplayForm() {
      this.$router.push({ name: 'display-form', params: { email: this.email, name: this.name } });
    },
    async getAllSubmissions() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.email);
      const doc = await type.get();
      const info = doc.data();
      if (info.updatedStep !== undefined) {
        this.myPayLoadData.currentStep = info.updatedStep;
      }
    },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    steps() {
      return [
        {
          name: 'Personal Details',
          count: 1,
          component: 'personalDetails',
        },
        {
          name: 'Family Details',
          count: 2,
          component: 'familyDetails',
        },
        {
          name: 'Academic Qualification',
          count: 3,
          component: 'academicDetails',
        },
        {
          name: 'Past Experience',
          count: 4,
          component: 'experienceDetails',
        },
        {
          name: 'Remuneration Details',
          count: 5,
          component: 'remunerationDetails',
        },
        {
          name: 'References',
          count: 6,
          component: 'referencesDetails',
        },
        {
          name: 'Complete',
          count: 7,
          component: 'completed',
        },
      ];
    },
    email() {
      return this.$store.state.user.currentUser.email;
    },
    name: {
      get() {
        if (this.$store.state.user.currentUser.name === null) {
          return this.$store.state.user.currentUser.email;
        }
        return this.$store.state.user.currentUser.name;
      },
      set(newName) {
        return newName;
      },
    },
  },
  mounted() {
    this.getAllSubmissions();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container {
    display: inline-block;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    margin: 5px;
}
textarea {
  width: 100%;
  padding: .7rem;
  border-radius: .3rem;
  border: 1px solid #333;
  outline: none;
}
</style>
