<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>Create new applicant</h3>
        </v-card-title>
        <v-card-text>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
          <v-form @submit.prevent="create()" ref="userForm" v-model="validLogin">
            <v-text-field
              color="primary"
              :disabled="loading"
              label="Applicant Email"
              placeholder="Enter Applicant Email"
              type="email"
              :rules="emailRules"
              v-model="email"
              required
            ></v-text-field>
            <v-text-field
              color="primary"
              :disabled="loading"
              label="Applicant Name"
              placeholder="Enter Applicant Name"
              :rules="nameRules"
              v-model="name"
              required
            ></v-text-field>
             <v-row align="center" justify="center" >
               <v-col md="2">
               <h3>Application type</h3>
               </v-col>
               <v-col md="3">
            <v-switch
            :label="typeOfRecruitment"
            v-model="postRecruitment"
            inset
            color="primary"
            ></v-switch>
               </v-col>
            </v-row>
            <v-btn type="submit" color="primary" :disabled="loading" :loading="loading">Create and Invite</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import firebase from 'firebase/app';
import moment from 'moment';
import config from '../../../config.js';

const axios = require('axios');

export default {
  data() {
    return {
      validLogin: true,
      loading: false,
      postRecruitment: true,
      error: {
        status: false,
        message: '',
      },
      success: {
        status: false,
        message: '',
      },
      email: '',
      name: '',
      password: '',
      typeOfRecruitment: 'Post-Recruitment',
    };
  },
  methods: {
    async create() {
      // here we will create a new user
      this.error.status = false;
      this.success.status = false;
      if (this.$refs.userForm.validate()) {
        this.loading = true;
        try {
          this.password = this.randomPassword(6);
          const firebaseSignUpInstance = firebase.initializeApp(config, 'secondary');
          await firebaseSignUpInstance.auth().createUserWithEmailAndPassword(this.email, this.password)
            .then(() => {
              // Signed in
              firebaseSignUpInstance.auth().signOut();
              firebaseSignUpInstance.delete();
              this.addLog();
              this.addToSectionIds();
              this.storeUser();
            });
        } catch (error) {
          this.error.message = 'User creation unsuccessful. Please try again later';
          this.error.status = true;
          this.loading = false;
        }
      }
    },
    async addLog() {
      await this.firebase.firestore().collection('actionLog').add({
        profileName: this.name,
        profileMail: this.email,
        timeStamp: moment(this.theDate).format('DD/MMM/YYYY HH:mm:ss'),
        delete: false,
        signature: this.$store.state.user.currentUser.email,
      });
      // console.log('12323', res);
    },
    async sendInvite() {
      const dataToSend = {
        email: this.email,
        password: this.password,
        type: 'candidate',
      };
      const bufferObj = Buffer.from(JSON.stringify(dataToSend), 'utf8');
      // Encode the Buffer as a base64 string
      const encodedData = bufferObj.toString('base64');
      const responseAx = await axios({
        // -praform-a3e92
        url: 'https://us-central1-manipal-pra-app.cloudfunctions.net/sendMail',
        method: 'post',
        data: {
          payload: encodedData,
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      });
      if (responseAx.data === 'Send mail Sended') {
        this.success.message = `${this.email} added successfully and invited`;
        this.success.status = true;
        this.email = '';
        this.name = '';
        this.password = '';
        this.$refs.userForm.resetValidation();
        this.loading = false;
      } else {
        this.success.message = `${this.email} created but invite unsuccessful`;
        this.success.status = true;
        this.loading = false;
      }
    },
    async addToSectionIds() {
      try {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.email).set({
          name: this.name,
          email: this.email,
          postRecruitment: this.postRecruitment,
          updatedStep: 1,
        });
      } catch (error) {
        // console.log(error);
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
        // this.error.message = 'Something went wrong. Please try in some time.';
        // this.error.status = true;
        this.loading = false;
      }
    },
    async storeUser() {
      try {
        await this.firebase.firestore().collection('usersList').doc(this.email).set({
          name: this.name,
          email: this.email,
          password: this.password,
          postRecruitment: this.postRecruitment,
          createdBy: this.$store.state.user.currentUser.email,
          writer: true,
        });
        this.sendInvite();
      } catch (error) {
        // console.log(error);
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
        this.loading = false;
      }
    },
    randomPassword(length) {
      const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let password = '';
      let i = '';
      let x = '';
      for (x = 0; x < length; x += 1) {
        i = Math.floor(Math.random() * chars.length);
        password += chars.charAt(i);
      }
      return password;
    },
  },
  computed: {
    nameRules() {
      return [
        (v) => !!v || 'Name is required',
        (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
        (v) => /[A-Z ]/gi.test(v) || 'name cannot consist of digits or special characters',
        (v) => (v && v.length <= 250) || 'name must be less than 250 characters',
      ];
    },
    emailRules() {
      return [
        (v) => !!v || 'Email is required',
        (v) => v === null || (v.length === 0) || /.+@.+\..+/.test(v) || 'Email must be valid',
      ];
    },
  },
  watch: {
    postRecruitment() {
      if (this.postRecruitment) {
        this.typeOfRecruitment = 'Post-Recruitment';
      } else {
        this.typeOfRecruitment = 'Pre-Recruitment';
      }
    },
  },
};
</script>
