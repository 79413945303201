<template>
<v-form
          ref="formAcademicDetails"
      v-model="validAcademicDetails"
        >
      <div class="container">
        <label>Academics<span class="red--text"><strong> * </strong></span></label>
      <v-data-table
       v-if="academics.length>0"
      :headers="academicsheaders"
      :items="academics"
      :server-items-length="academics.length"
      hide-action
      :hide-default-footer="true"
      item-key="props.index">
      <template v-slot:item.specialization="props">
            <v-text-field v-model="props.item.specialization" :rules="basicRules"  ></v-text-field>
      </template>
      <template v-slot:item.institute="props">
            <v-text-field v-model="props.item.institute" :rules="basicRules" ></v-text-field>
      </template>
      <template v-slot:item.marks="props">
            <v-text-field v-model="props.item.marks" :rules="basicRules" ></v-text-field>
      </template>
      <template v-slot:item.yearFrom="props">
           <v-text-field v-model="props.item.yearFrom" :rules="type ? basicRules : []" :class="{'required': type}"></v-text-field>
      </template>
      <template v-slot:item.yearTo="props">
            <v-text-field v-model="props.item.yearTo" :rules="type ? basicRules : []" :class="{'required': type}"></v-text-field>
      </template>
      <template v-if="this.academics.length < 4" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deleteAcademicRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="checkAdd()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <br><br><br>
    <label>MEMBERSHIP OF PROFESSIONAL ASSOCIATIONS </label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="proffesionalAssociations" ></v-textarea>
        <v-card>
        <v-card-actions class="justify-space-between">
            <v-btn class="mr-4" color="primary" @click="$emit('update:currentStep', currentStep -1);">
      Back
    </v-btn>
        <v-btn class="mr-4"
          color="primary"
          :disabled="loading" :loading="loading"
          @click="validateAcademicDetails();"
        >
          Next
        </v-btn>
        </v-card-actions>
    </v-card>
      </div>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </v-form>
</template>

<script>
import moment from 'moment';

export default {
  props: { currentStep: Number },
  data: () => ({
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    academicsheaders: [
      { text: 'Degree/Diploma & Specialization *', value: 'specialization' },
      { text: 'College/University/Institute *', value: 'institute' },
      { text: 'Class/Grade *', value: 'marks' },
      { text: 'Year From', value: 'yearFrom' },
      { text: 'To', value: 'yearTo' },
      { text: 'Actions', value: 'action' },
    ],
    submittedId: '',
    date: '',
    menu: false,
    modal: false,
    academics: [],
    proffesionalAssociations: '',
    validAcademicDetails: true,
    loading: false,
    error: {
      status: false,
      message: '',
    },
    refKey: '',
    type: false,
  }),
  mounted() {
    this.addAcademicRow();
    // console.log(this.currentStep);
    this.getRefKey();
  },
  methods: {
    checkAdd() {
      if (this.$refs.formAcademicDetails.validate()) {
        this.addAcademicRow();
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    validateAcademicDetails() {
      if (this.$refs.formAcademicDetails.validate()) {
        if (this.verifyAcademicTable) {
          this.submitAcademicDetails();
        } else {
          this.error.message = 'Please provide atleast 10th/SSLC, 12th/PUC, and Degree academic information';
          this.error.status = true;
        }
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    async singleEmit() {
      this.$emit('update:currentStep', this.currentStep + 1);
      if (this.refKey === '' || this.refKey === undefined) {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          academicDetails: this.submittedId,
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      }
    },
    verifyAcademicTable() {
      if (this.academics.length > 3) {
        return true;
      }
      return false;
    },
    async submitAcademicDetails() {
      this.loading = true;
      try {
        if (this.refKey === '' || this.refKey === undefined) {
          const res = await this.firebase.firestore().collection('academicDetails').add({
            academics: this.academics,
            proffesionalAssociations: this.proffesionalAssociations,
          });
          this.submittedId = res.id;
          this.loading = false;
          this.singleEmit();
        } else {
          await this.firebase.firestore().collection('academicDetails').doc(this.refKey).update({
            academics: this.academics,
            proffesionalAssociations: this.proffesionalAssociations,
          });
          this.loading = false;
          this.singleEmit();
        }
      } catch (error) {
      //  console.log(error);
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
        this.loading = false;
      }
    },
    async getAcademicDetails() {
      const academicDetails = await this.firebase.firestore().collection('academicDetails').doc(this.refKey);
      const doc = await academicDetails.get();
      const info = doc.data();
      this.academics = info.academics;
      this.proffesionalAssociations = info.proffesionalAssociations;
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey);
      const doc = await type.get();
      const info = doc.data();
      this.type = info.postRecruitment;
      this.refKey = info.academicDetails;
    },
    addAcademicRow() {
      const newRow = {
        yearFrom: '',
        yearTo: '',
        specialization: '',
        institute: '',
        marks: '',
        id: this.academics.length,
      };
      this.academics.push(newRow);
    },
    deleteAcademicRow(index) {
      this.academics.splice(index, 1);
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    save(index) {
      // console.log('current index', index);
      this.academics[index].yearFrom = this.formatDate(this.date);
      this.modal = false;
      this.date = '';
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getAcademicDetails();
        }
      }
    },
  },
  computed: {
    dockey() {
      return this.$store.state.user.currentUser.email;
    },
  },
};
</script>
