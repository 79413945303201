<template>
<v-form
          ref="formExpereinceDetails"
      v-model="validExpereinceDetails"
        >
      <div class="container">
        <v-radio-group v-model="fresher">
       <label> Are you a Fresher<span class="red--text"><strong> *</strong></span></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
      <div v-if="fresher">
        <label> Please provide internship details (if any) </label>
          <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="internshipDetails"></v-textarea>
      </div>
      <div v-if="!fresher">
      <v-data-table
       v-if="experience.length>0"
      :headers="experienceheaders"
      :items="experience"
      :server-items-length="experience.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name"  :rules="textRules"></v-text-field>
      </template>
      <template v-slot:item.designation="props">
            <v-text-field v-model="props.item.designation" :rules="basicRules"></v-text-field>
      </template>
      <template v-slot:item.code="props">
            <v-text-field v-model="props.item.code" :rules="basicRules"></v-text-field>
      </template>
      <template v-slot:item.fromDate="props">
            <v-edit-dialog
            :return-value.sync="props.item.fromDate"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.fromDate != '' ? formatedDate(props.item.fromDate) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update date
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.fromDate"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.fromDate"
              no-title
              @change="$refs.menu.save()"
              :value="datePickerFormattedDate"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
       <template v-slot:item.toDate="props">
            <v-edit-dialog
            :return-value.sync="props.item.toDate"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.toDate != '' ? formatedDate(props.item.toDate) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update date
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.toDate"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.toDate"
              no-title
              @change="$refs.menu.save()"
              :value="datePickerFormattedDate"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
       <template v-slot:item.ctc="props">
            <v-text-field v-model="props.item.ctc" :rules="basicRules"></v-text-field>
      </template>
       <template v-slot:item.takeHome="props">
            <v-text-field v-model="props.item.takeHome" :rules="basicRules"></v-text-field>
      </template>
      <template v-if="this.experience.length == 1" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deleteExpRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addExpRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <br><br><br>
    <v-divider>
    </v-divider>
    <label >DETAILS OF PRESENT APPOINTMENT </label>
    <v-text-field
      label="Name of Current Organization"
      v-model="currentOrganizationName"
      :class="{'required': type}"
      :rules="type ? textRules : []"
    ></v-text-field><v-text-field
      label="Address of Current Organization"
      v-model="organizationAddress"
      :class="{'required': type}"
      :rules="type ? textRules : []"
    ></v-text-field><v-text-field
      label="Nature of Business / Industry"
      v-model="businessNature"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Total Number of Employees"
      v-model="totalEmployees"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Designation on Joining"
      v-model="designationOnJoining"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Designation at Present"
      v-model="designationAtPresent"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Reporting Officer Name"
      v-model="reportingOfficerName"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Reporting Officer Designation"
      v-model="reportingOfficeDesignation"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Monthly Fixed Take Home"
      v-model="monthlyFixedTakeHome"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field><v-text-field
      label="Annual Sales Turnover"
      v-model="annualSalesTurnover"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field>
     <v-dialog
          persistent
          v-model="effectiveModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
            label="Effective Date"
            v-model="effectiveDate"
            :rules="type ? basicRules : []"
            :class="{'required': type}"
            v-on="on"
    ></v-text-field>
          </template>
          <v-date-picker v-model="fetchedDate" scrollable actions>
            <template v-scope="{ saveTheEffectiveDate, cancelTheEffectiveDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheEffectiveDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheEffectiveDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
    <vue-tel-input-vuetify
          mode="international"
      label="Reporting Officer's Mobile No."
      v-model="reportingOfficerMobile"
      :class="{'required': type}"
      :rules="type ? mobRules : []"
    ></vue-tel-input-vuetify><vue-tel-input-vuetify
          mode="international"
      label="Office Tel No."
      v-model="officeTelephoneNumber"
      :class="{'required': type}"
      :rules="type ? mobRules : []"
    ></vue-tel-input-vuetify><v-text-field
      label="Yearly Fixed CTC"
      v-model="yearlyFixedCTC"
      :class="{'required': type}"
      :rules="type ? basicRules : []"
    ></v-text-field>
    <v-divider>
    </v-divider>
    <label>Outline your Job Responsibilities, Reporting Relationship
      (i.e. to whom you are responsible and who all are responsible to you) and Product / Division (s).
      Major achievements in your current assignment or in the last 2 years.
      (if presently unemployed, give details of your last assignment with joining & leaving dates)
      <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="jobResponsibilities" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    </div>
    <label>Mention any other information, which you think, should be taken into account in considering your application.
      For example : strengths & weaknesses; reasons for seeking change etc.
      <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="strengthsAndWeakness" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <label>What did you achieve in your last job? [ please specify the details ]
      <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="acheivementDetails" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <label>Tell us about a big challenge/difficulty you have faced, how did you deal with it?
      <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="challengesFaced" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <label>What are your personal goals and how do you think that MANIPAL GROUP can help you in realizing your goals ?
      <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="personalGoals" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <label>What do you think about your last boss / employer ?  <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="lastBossThoughts" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <label>How many years you are willing to commit with us. <span class="red--text" v-if="type"><strong> * </strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="yearsCommitting" :rules="type ? basicRules : []"></v-textarea>
    <br>
    <br>
    <br>
    <v-card>
        <v-card-actions class="justify-space-between">
            <v-btn class="mr-4" color="primary" @click="$emit('update:currentStep', currentStep -1);">
      Back
    </v-btn>
        <v-btn class="mr-4"
          color="primary"
          :disabled="loading" :loading="loading"
          @click="validateExperienceDetails();"
        >
          Next
        </v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </div>
        </v-form>
</template>

<script>
import moment from 'moment';

export default {
  props: { currentStep: Number },
  data: () => ({
    validExpereinceDetails: true,
    loading: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    emailRules: [

      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    phoneRules: [
      (v) => (v.length < 7 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    experienceheaders: [
      { text: 'Name of Organization', value: 'name' },
      { text: 'Last Designation', value: 'designation' },
      { text: 'Employee Code', value: 'code' },
      { text: 'Worked From(Date)', value: 'fromDate' },
      { text: 'Worked Till (Date)', value: 'toDate' },
      { text: 'Yearly Fixed CTC (Rs.)', value: 'ctc' },
      { text: 'Monthly Fixed Take Home(Rs.)', value: 'takeHome' },
      { text: 'Actions', value: 'action' },
    ],
    submittedId: '',
    fresher: false,
    internshipDetails: '',
    experience: [],
    currentOrganizationName: '',
    organizationAddress: '',
    businessNature: '',
    totalEmployees: '',
    designationOnJoining: '',
    designationAtPresent: '',
    reportingOfficerName: '',
    reportingOfficeDesignation: '',
    monthlyFixedTakeHome: '',
    annualSalesTurnover: '',
    effectiveDate: '',
    fetchedDate: '',
    effectiveModal: '',
    reportingOfficerMobile: '',
    officeTelephoneNumber: '',
    yearlyFixedCTC: '',
    jobResponsibilities: '',
    strengthsAndWeakness: '',
    acheivementDetails: '',
    challengesFaced: '',
    personalGoals: '',
    lastBossThoughts: '',
    yearsCommitting: '',
    currentDate: moment(new Date()),
    error: {
      status: false,
      message: '',
    },
    refKey: '',
    type: false,
  }),
  methods: {
    validateExperienceDetails() {
      if (this.$refs.formExpereinceDetails.validate()) {
        this.submitExperienceDetails();
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    async singleEmit() {
      this.$emit('update:currentStep', this.currentStep + 1);
      if (this.refKey === '' || this.refKey === undefined) {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          previousExperience: this.submittedId,
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      }
    },
    async submitExperienceDetails() {
      this.loading = true;
      try {
        if (this.refKey === '' || this.refKey === undefined) {
          const res = await this.firebase.firestore().collection('previousExperience').add({
            experience: this.experience,
            currentOrganizationName: this.currentOrganizationName,
            organizationAddress: this.organizationAddress,
            businessNature: this.businessNature,
            totalEmployees: this.totalEmployees,
            designationOnJoining: this.designationOnJoining,
            designationAtPresent: this.designationAtPresent,
            reportingOfficerName: this.reportingOfficerName,
            reportingOfficeDesignation: this.reportingOfficeDesignation,
            monthlyFixedTakeHome: this.monthlyFixedTakeHome,
            annualSalesTurnover: this.annualSalesTurnover,
            effectiveDate: this.effectiveDate,
            reportingOfficerMobile: this.reportingOfficerMobile,
            officeTelephoneNumber: this.officeTelephoneNumber,
            yearlyFixedCTC: this.yearlyFixedCTC,
            jobResponsibilities: this.jobResponsibilities,
            strengthsAndWeakness: this.strengthsAndWeakness,
            acheivementDetails: this.acheivementDetails,
            challengesFaced: this.challengesFaced,
            personalGoals: this.personalGoals,
            lastBossThoughts: this.lastBossThoughts,
            yearsCommitting: this.yearsCommitting,
            fresher: this.fresher,
            internshipDetails: this.internshipDetails,
          });
          this.submittedId = res.id;
          this.loading = false;
          this.singleEmit();
        } else {
          await this.firebase.firestore().collection('previousExperience').doc(this.refKey).update({
            experience: this.experience,
            currentOrganizationName: this.currentOrganizationName,
            organizationAddress: this.organizationAddress,
            businessNature: this.businessNature,
            totalEmployees: this.totalEmployees,
            designationOnJoining: this.designationOnJoining,
            designationAtPresent: this.designationAtPresent,
            reportingOfficerName: this.reportingOfficerName,
            reportingOfficeDesignation: this.reportingOfficeDesignation,
            monthlyFixedTakeHome: this.monthlyFixedTakeHome,
            annualSalesTurnover: this.annualSalesTurnover,
            effectiveDate: this.effectiveDate,
            reportingOfficerMobile: this.reportingOfficerMobile,
            officeTelephoneNumber: this.officeTelephoneNumber,
            yearlyFixedCTC: this.yearlyFixedCTC,
            jobResponsibilities: this.jobResponsibilities,
            strengthsAndWeakness: this.strengthsAndWeakness,
            acheivementDetails: this.acheivementDetails,
            challengesFaced: this.challengesFaced,
            personalGoals: this.personalGoals,
            lastBossThoughts: this.lastBossThoughts,
            yearsCommitting: this.yearsCommitting,
            fresher: this.fresher,
            internshipDetails: this.internshipDetails,
          });
          this.loading = false;
          this.singleEmit();
        }
      } catch (error) {
      //  console.log(error);
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
        this.loading = false;
      }
    },
    async getExperienceDetails() {
      const pastExperienceDetails = await this.firebase.firestore().collection('previousExperience').doc(this.refKey);
      const doc = await pastExperienceDetails.get();
      const info = doc.data();
      this.experience = info.experience;
      this.currentOrganizationName = info.currentOrganizationName;
      this.organizationAddress = info.organizationAddress;
      this.businessNature = info.businessNature;
      this.totalEmployees = info.totalEmployees;
      this.designationOnJoining = info.designationOnJoining;
      this.designationAtPresent = info.designationAtPresent;
      this.reportingOfficerName = info.reportingOfficerName;
      this.reportingOfficeDesignation = info.reportingOfficeDesignation;
      this.monthlyFixedTakeHome = info.monthlyFixedTakeHome;
      this.annualSalesTurnover = info.annualSalesTurnover;
      this.effectiveDate = info.effectiveDate;
      this.reportingOfficerMobile = info.reportingOfficerMobile;
      this.officeTelephoneNumber = info.officeTelephoneNumber;
      this.yearlyFixedCTC = info.yearlyFixedCTC;
      this.jobResponsibilities = info.jobResponsibilities;
      this.strengthsAndWeakness = info.strengthsAndWeakness;
      this.acheivementDetails = info.acheivementDetails;
      this.challengesFaced = info.challengesFaced;
      this.personalGoals = info.personalGoals;
      this.lastBossThoughts = info.lastBossThoughts;
      this.yearsCommitting = info.yearsCommitting;
      this.fresher = info.fresher;
      this.internshipDetails = info.internshipDetails;
    },
    async getRefKey() {
      console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey);
      const doc = await type.get();
      const info = doc.data();
      this.type = info.postRecruitment;
      this.refKey = info.previousExperience;
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      console.log('Dialog closed');
    },
    deleteExpRow(index) {
      this.experience.splice(index, 1);
    },
    addExpRow() {
      const newRow = {
        fromDate: '',
        toDate: '',
        specialization: '',
        institute: '',
        marks: '',
        id: this.experience.length,
      };
      this.experience.push(newRow);
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    cancelTheEffectiveDate() {
      this.effectiveModal = false;
    },
    saveTheEffectiveDate() {
      this.effectiveModal = false;
      this.effectiveDate = this.formatedDate(this.fetchedDate);
    },
  },
  mounted() {
    this.addExpRow();
    this.getRefKey();
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getExperienceDetails();
        }
      }
    },
  },
  computed: {
    dockey() {
      return this.$store.state.user.currentUser.email;
    },
    formattedDate() {
      return this.theDate ? moment(this.theDate).format('DD/MMM/YYYY') : moment(new Date()).format('DD/MMM/YYYY'); // Custom format
    },
    datePickerFormattedDate() {
      return this.theDate ? moment(this.theDate).format('DD-MMM-YYYY')
        : moment(new Date()).format('DD-MMM-YYYY'); // Date picker objects needs date in this particular format
    },
  },
};
</script>
