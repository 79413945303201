<template>
  <v-alert
      :value="true"
      type="success"
    >
      Thank you for your submission, we will soon get back to you.
    </v-alert>
</template>
<script>
export default {
};
</script>
