<template>
  <v-form
          ref="formRemunerationDetails"
          v-model="validRemunerationDetails"
        >
        <div class="container">
          <label>On Joining </label>
          <v-text-field
            label="Basic (₹)"
            v-model="joiningBasic"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="DA (₹)"
            v-model="joiningDA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="HRA (₹)"
            v-model="joiningHRA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Conveyance (₹)"
            v-model="joiningConveyance"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Any other allowance (₹)"
            v-model="joiningOtherAllowance"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Gross Salary (₹)"
            v-model="joiningGrossSalary"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="LTA (₹)"
            v-model="joiningLTA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Medical (₹)"
            v-model="joiningMedical"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Annual Bonus (₹)"
            v-model="joiningAnnualBonus"
          ></v-text-field>
          <v-text-field
            label="Club Membership (₹)"
            v-model="joiningClubMembership"
          ></v-text-field>
          <v-text-field
            label="Others (₹)"
            v-model="joiningOthers"
          ></v-text-field>
          <v-text-field
            label="Monthly CTC (₹)"
            v-model="joiningMonthlyCTC"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Yearly CTC (monthly CTC x 12) (₹)"
            v-model="joiningYearlyCTC"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <label>At Present </label>
          <v-text-field
            label="Basic (₹)"
            v-model="presentBasic"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="DA (₹)"
            v-model="presentDA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="HRA (₹)"
            v-model="presentHRA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Conveyance (₹)"
            v-model="presentConveyance"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Any other allowance (₹)"
            v-model="presentOtherAllowance"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Gross Salary (₹)"
            v-model="presentGrossSalary"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="LTA (₹)"
            v-model="presentLTA"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Medical (₹)"
            v-model="presentMedical"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Annual Bonus (₹)"
            v-model="presentAnnualBonus"
          ></v-text-field>
          <v-text-field
            label="Club Membership (₹)"
            v-model="presentClubMembership"
          ></v-text-field>
          <v-text-field
            label="Others (₹)"
            v-model="presentOthers"
          ></v-text-field>
          <v-text-field
            label="Monthly CTC (₹)"
            v-model="presentMonthlyCTC"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-text-field
            label="Yearly CTC (monthly CTC x 12) (₹)"
            v-model="presentYearlyCTC"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <label >RETIREMENT BENEFITS IN YOUR CURRENT ORGANIZATION</label>
          <v-checkbox
            v-model="providentFund"
            :label="`Provident Fund `"
          ></v-checkbox>
          <v-checkbox
            v-model="gratuity"
            :label="`Gratuity`"
          ></v-checkbox>
          <v-checkbox
            v-model="pension"
            :label="`Pension/Superannuation`"
          ></v-checkbox>
          <v-checkbox
            v-model="othersRetirmentBenefit"
            :label="`Others`"
          ></v-checkbox>
          <div  v-if="othersRetirmentBenefit">
          <label>Please mention other retirment benefits</label>
          <v-textarea rows="2" row-height="18"  name="proffesionalAssociations"
          v-model="othersRetirmentBenefitDescription" :rules="basicRules"></v-textarea>
          </div>
          <label>LEAVE BENEFITS IN CURRENT YOUR ORGANIZATION </label>
          <v-data-table
       v-if="leaves.length>0"
      :headers="leavesheaders"
      :items="leaves"
      :server-items-length="leaves.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.name="props">
        <v-text-field v-model="props.item.name" ></v-text-field>
      </template>
      <template v-slot:item.leavesPerYear="props">
            <v-text-field v-model="props.item.leavesPerYear" ></v-text-field>
      </template>
      <template v-slot:item.details="props">
            <v-text-field v-model="props.item.details" ></v-text-field>
      </template>
      <template v-if="this.leaves.length == 1" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deleteLeavesRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addLeavesRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <br><br><br>
          <label>YOUR EXPECTED REMUNERATION </label>
          <v-text-field
            label="Monthly Fixed Takehome (₹)"
            v-model="expectedMonthlyFixedTakeHome"
            class="required"
            :rules="digitRules"
          ></v-text-field>
          <v-text-field
            label="Yearly Fixed CTC (₹)"
            v-model="expectedYearlyFixedCTC"
            class="required"
            :rules="digitRules"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <v-text-field
            label="Time required for joining our Organization (Notice Period; if selected) in days"
            v-model="noticePeriodInDays"
            class="required"
            :rules="digitRules"
          ></v-text-field>
          <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
            <v-radio-group v-model="relocationWill">
          <label>Are you open to relocate if required<span class="red--text"><strong> *</strong></span></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
            </v-radio-group>
          </div>
          <label v-if="!relocationWill">Please provide details<span class="red--text"><strong> *</strong></span></label>
          <v-textarea rows="2" row-height="18" v-if="!relocationWill" name="notRelocate" v-model="notRelocateDetails"></v-textarea>
           <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
          <v-radio-group v-model="presentServiceBond">
       <label>Are you under any service bond/contract with your present organization?<span class="red--text"><strong> *</strong></span></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
          </div>
          <label v-if="presentServiceBond">Please provide details</label>
          <v-textarea rows="2" row-height="18" v-if="presentServiceBond" name="serviceBond" v-model="serviceBondDetails"></v-textarea>
          <v-radio-group v-model="refferalsInfo">
        <label >Is any of your Relative(s) / Friend(s) / Acquaintance(s) currently working with any Manipal Group companies ?  </label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
          <label v-if="refferalsInfo">Please provide details</label>
          <v-data-table
       v-if="refferalsInfo"
      :headers="refferalsheaders"
      :items="refferals"
      :server-items-length="refferals.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.company="props">
            <v-text-field v-model="props.item.company" :rules="textRules"></v-text-field>
      </template>
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="textRules"></v-text-field>
      </template>
      <template v-slot:item.code="props">
            <v-text-field v-model="props.item.code" ></v-text-field>
      </template>
      <template v-slot:item.designation="props">
            <v-text-field v-model="props.item.designation" :rules="basicRules"></v-text-field>
      </template>
      <template v-slot:item.location="props">
            <v-text-field v-model="props.item.location" :rules="basicRules"></v-text-field>
      </template>
      <template v-slot:item.association="props">
            <v-text-field v-model="props.item.association" :rules="basicRules"></v-text-field>
      </template>
      <template v-if="this.refferals.length == 1" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deleteRefferalsRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" v-if="refferalsInfo" @click="addRefferalsRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <br><br><br>
    <v-radio-group v-model="homeInternetConnectivity">
          <label >Do you have good INTERNET Connectivity at HOME</label>
    <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
        <br>
    <label>Self : (INTRODUCTION Please specify the details)<span class="red--text"><strong> *</strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="selfIntro" :rules="basicRules"></v-textarea>
    <br>
    <br>
    <br>
    <label>Please explain in brief why you consider yourself suitable for the position applied for?
      <span class="red--text"><strong> *</strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="jobSuitableReason" :rules="basicRules"></v-textarea>
    <br>
    <br>
    <br>
    <label>Which functional area do you prefer?<span class="red--text"><strong> *</strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="functionalAreaPreference" :rules="basicRules"></v-textarea>
    <br>
    <br>
    <br>
    <label>Are you planning for higher studies?<span class="red--text"><strong> *</strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="higherStudiesDetails" :rules="basicRules"></v-textarea>
    <br>
    <br>
    <br>
    <label>Weekly off day(s) in your present employment <span class="red--text"><strong> *</strong></span></label>
    <v-textarea rows="2" row-height="18" name="proffesionalAssociations" v-model="weeklyOffDaysDetails" :rules="basicRules"></v-textarea>
    <br>
    <br>
    <br>
    <br>
    <v-radio-group v-model="previouslyInterviedByUs">
          <label >Have you been previously tested/interviewed by us/Manipal group companies ? <span class="red--text"><strong> *</strong>
          </span></label>
          <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
        <br>
        <label v-if="previouslyInterviedByUs">Please provide details</label>
        <v-data-table
       v-if="previouslyInterviedByUs"
      :headers="previouslyTestedheaders"
      :items="previouslyTested"
      :server-items-length="previouslyTested.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.company="props">
            <v-text-field v-model="props.item.company" :rules="textRules"></v-text-field>
      </template>
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="textRules"></v-text-field>
      </template>
      <template v-slot:item.location="props">
            <v-text-field v-model="props.item.location" :rules="basicRules"></v-text-field>
      </template>
      <template v-slot:item.date="props">
            <v-edit-dialog
            :return-value.sync="props.item.date"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.date != '' ? formatedDate(props.item.date) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update date
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.date"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.date"
              no-title
              @change="$refs.menu.save()"
              :value="datePickerFormattedDate"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
      <template v-if="this.previouslyTested.length == 1" v-slot:item.action="">
      </template>
      <template v-else v-slot:item.action="props">
            <v-btn class="mr-4" v-model="props.item.action" @click="deletePreviouslyTestedRow(props.index)">
              <v-icon dark right>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addPreviouslyTestedRow()" v-if="previouslyInterviedByUs"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout>
    <br><br><br>
    <v-card>
        <v-card-actions class="justify-space-between">
            <v-btn class="mr-4" color="primary" @click="$emit('update:currentStep', currentStep -1);">
      Back
    </v-btn>
        <v-btn class="mr-4"
          color="primary"
          :disabled="loading" :loading="loading"
          @click="validateRemunerationDetails();"
        >
          Next
        </v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </div>
          </v-form>
</template>
<script>
import moment from 'moment';

export default {
  props: { currentStep: Number },
  data: () => ({
    basicRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 100000) || 'Field must be less than 100000 characters',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
      (v) => (v && v.length <= 100000) || 'Field must be less than 100000 characters',
    ],
    submittedId: '',
    validRemunerationDetails: true,
    loading: false,
    joiningBasic: '',
    joiningDA: '',
    joiningHRA: '',
    joiningConveyance: '',
    joiningOtherAllowance: '',
    joiningGrossSalary: '',
    joiningLTA: '',
    joiningMedical: '',
    joiningAnnualBonus: '',
    joiningClubMembership: '',
    joiningOthers: '',
    joiningMonthlyCTC: '',
    joiningYearlyCTC: '',
    presentBasic: '',
    presentDA: '',
    presentHRA: '',
    presentConveyance: '',
    presentOtherAllowance: '',
    presentGrossSalary: '',
    presentLTA: '',
    presentMedical: '',
    presentAnnualBonus: '',
    presentClubMembership: '',
    presentOthers: '',
    presentMonthlyCTC: '',
    presentYearlyCTC: '',
    providentFund: false,
    gratuity: false,
    pension: false,
    othersRetirmentBenefit: false,
    expectedMonthlyFixedTakeHome: '',
    expectedYearlyFixedCTC: '',
    noticePeriodInDays: '',
    relocationWill: true,
    notRelocateDetails: '',
    othersRetirmentBenefitDescription: '',
    presentServiceBond: false,
    serviceBondDetails: '',
    refferalsInfo: false,
    homeInternetConnectivity: true,
    selfIntro: '',
    jobSuitableReason: '',
    functionalAreaPreference: '',
    higherStudiesDetails: '',
    weeklyOffDaysDetails: '',
    previouslyInterviedByUs: false,
    leaves: [],
    leavesheaders: [
      { text: 'Leave Name', value: 'name' },
      { text: 'Leaves/Year', value: 'leavesPerYear' },
      { text: 'Details(if any)', value: 'details' },
      { text: 'Actions', value: 'action' },
    ],
    previouslyTested: [],
    previouslyTestedheaders: [
      { text: 'Company Name', value: 'company' },
      { text: 'Position Name', value: 'name' },
      { text: 'Job Location', value: 'location' },
      { text: 'Date of Interview/Test', value: 'date' },
      { text: 'Actions', value: 'action' },
    ],
    refferals: [],
    refferalsheaders: [
      { text: 'Company Name', value: 'company' },
      { text: 'Relative / Friend / Acquaintance Name(s)', value: 'name' },
      { text: 'Employee Code', value: 'code' }, // not mandatory
      { text: 'Designation', value: 'designation' },
      { text: 'Job Location', value: 'location' },
      { text: 'Association with You', value: 'association' },
      { text: 'Actions', value: 'action' },
    ],
    error: {
      status: false,
      message: '',
    },
    refKey: '',
    type: false,
    currentDate: moment(new Date()),
  }),
  methods: {
    validateRemunerationDetails() {
      if (this.$refs.formRemunerationDetails.validate()) {
        this.submitRemunerationDetails();
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    async singleEmit() {
      this.$emit('update:currentStep', this.currentStep + 1);
      if (this.refKey === '' || this.refKey === undefined) {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          remunerationDetails: this.submittedId,
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      }
    },
    async submitRemunerationDetails() {
      this.loading = true;
      try {
        if (this.refKey === '' || this.refKey === undefined) {
          const res = await this.firebase.firestore().collection('remunerationDetails').add({
            joiningBasic: this.joiningBasic,
            joiningDA: this.joiningDA,
            joiningHRA: this.joiningHRA,
            joiningConveyance: this.joiningConveyance,
            joiningOtherAllowance: this.joiningOtherAllowance,
            joiningGrossSalary: this.joiningGrossSalary,
            joiningLTA: this.joiningLTA,
            joiningMedical: this.joiningMedical,
            joiningAnnualBonus: this.joiningAnnualBonus,
            joiningClubMembership: this.joiningClubMembership,
            joiningOthers: this.joiningOthers,
            joiningMonthlyCTC: this.joiningMonthlyCTC,
            joiningYearlyCTC: this.joiningYearlyCTC,
            presentBasic: this.presentBasic,
            presentDA: this.presentDA,
            presentHRA: this.presentHRA,
            presentConveyance: this.presentConveyance,
            presentOtherAllowance: this.presentOtherAllowance,
            presentGrossSalary: this.presentGrossSalary,
            presentLTA: this.presentLTA,
            presentMedical: this.presentMedical,
            presentAnnualBonus: this.presentAnnualBonus,
            presentClubMembership: this.presentClubMembership,
            presentOthers: this.presentOthers,
            presentMonthlyCTC: this.presentMonthlyCTC,
            presentYearlyCTC: this.presentYearlyCTC,
            providentFund: this.providentFund,
            gratuity: this.gratuity,
            pension: this.pension,
            othersRetirmentBenefit: this.othersRetirmentBenefit,
            expectedMonthlyFixedTakeHome: this.expectedMonthlyFixedTakeHome,
            expectedYearlyFixedCTC: this.expectedYearlyFixedCTC,
            noticePeriodInDays: this.noticePeriodInDays,
            relocationWill: this.relocationWill,
            notRelocateDetails: this.notRelocateDetails,
            presentServiceBond: this.presentServiceBond,
            serviceBondDetails: this.serviceBondDetails,
            refferalsInfo: this.refferalsInfo,
            homeInternetConnectivity: this.homeInternetConnectivity,
            selfIntro: this.selfIntro,
            jobSuitableReason: this.jobSuitableReason,
            functionalAreaPreference: this.functionalAreaPreference,
            higherStudiesDetails: this.higherStudiesDetails,
            weeklyOffDaysDetails: this.weeklyOffDaysDetails,
            previouslyInterviedByUs: this.previouslyInterviedByUs,
            leaves: this.leaves,
            previouslyTested: this.previouslyTested,
            refferals: this.refferals,
            othersRetirmentBenefitDescription: this.othersRetirmentBenefitDescription,
          });
          this.submittedId = res.id;
          this.loading = false;
          this.singleEmit();
        } else {
          await this.firebase.firestore().collection('remunerationDetails').doc(this.refKey).update({
            joiningBasic: this.joiningBasic,
            joiningDA: this.joiningDA,
            joiningHRA: this.joiningHRA,
            joiningConveyance: this.joiningConveyance,
            joiningOtherAllowance: this.joiningOtherAllowance,
            joiningGrossSalary: this.joiningGrossSalary,
            joiningLTA: this.joiningLTA,
            joiningMedical: this.joiningMedical,
            joiningAnnualBonus: this.joiningAnnualBonus,
            joiningClubMembership: this.joiningClubMembership,
            joiningOthers: this.joiningOthers,
            joiningMonthlyCTC: this.joiningMonthlyCTC,
            joiningYearlyCTC: this.joiningYearlyCTC,
            presentBasic: this.presentBasic,
            presentDA: this.presentDA,
            presentHRA: this.presentHRA,
            presentConveyance: this.presentConveyance,
            presentOtherAllowance: this.presentOtherAllowance,
            presentGrossSalary: this.presentGrossSalary,
            presentLTA: this.presentLTA,
            presentMedical: this.presentMedical,
            presentAnnualBonus: this.presentAnnualBonus,
            presentClubMembership: this.presentClubMembership,
            presentOthers: this.presentOthers,
            presentMonthlyCTC: this.presentMonthlyCTC,
            presentYearlyCTC: this.presentYearlyCTC,
            providentFund: this.providentFund,
            gratuity: this.gratuity,
            pension: this.pension,
            othersRetirmentBenefit: this.othersRetirmentBenefit,
            expectedMonthlyFixedTakeHome: this.expectedMonthlyFixedTakeHome,
            expectedYearlyFixedCTC: this.expectedYearlyFixedCTC,
            noticePeriodInDays: this.noticePeriodInDays,
            relocationWill: this.relocationWill,
            notRelocateDetails: this.notRelocateDetails,
            presentServiceBond: this.presentServiceBond,
            serviceBondDetails: this.serviceBondDetails,
            refferalsInfo: this.refferalsInfo,
            homeInternetConnectivity: this.homeInternetConnectivity,
            selfIntro: this.selfIntro,
            jobSuitableReason: this.jobSuitableReason,
            functionalAreaPreference: this.functionalAreaPreference,
            higherStudiesDetails: this.higherStudiesDetails,
            weeklyOffDaysDetails: this.weeklyOffDaysDetails,
            previouslyInterviedByUs: this.previouslyInterviedByUs,
            leaves: this.leaves,
            previouslyTested: this.previouslyTested,
            refferals: this.refferals,
            othersRetirmentBenefitDescription: this.othersRetirmentBenefitDescription,
          });
          this.loading = false;
          this.singleEmit();
        }
      } catch (error) {
        // console.log(error);
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
      }
    },
    async getRemunerationDetails() {
      const remunerationDetails = await this.firebase.firestore().collection('remunerationDetails').doc(this.refKey);
      const doc = await remunerationDetails.get();
      const info = doc.data();
      this.joiningBasic = info.joiningBasic;
      this.joiningDA = info.joiningDA;
      this.joiningHRA = info.joiningHRA;
      this.joiningConveyance = info.joiningConveyance;
      this.joiningOtherAllowance = info.joiningOtherAllowance;
      this.joiningGrossSalary = info.joiningGrossSalary;
      this.joiningLTA = info.joiningLTA;
      this.joiningMedical = info.joiningMedical;
      this.joiningAnnualBonus = info.joiningAnnualBonus;
      this.joiningClubMembership = info.joiningClubMembership;
      this.joiningOthers = info.joiningOthers;
      this.joiningMonthlyCTC = info.joiningMonthlyCTC;
      this.joiningYearlyCTC = info.joiningYearlyCTC;
      this.presentBasic = info.presentBasic;
      this.presentDA = info.presentDA;
      this.presentHRA = info.presentHRA;
      this.presentConveyance = info.presentConveyance;
      this.presentOtherAllowance = info.presentOtherAllowance;
      this.presentGrossSalary = info.presentGrossSalary;
      this.presentLTA = info.presentLTA;
      this.presentMedical = info.presentMedical;
      this.presentAnnualBonus = info.presentAnnualBonus;
      this.presentClubMembership = info.presentClubMembership;
      this.presentOthers = info.presentOthers;
      this.presentMonthlyCTC = info.presentMonthlyCTC;
      this.presentYearlyCTC = info.presentYearlyCTC;
      this.providentFund = info.providentFund;
      this.gratuity = info.gratuity;
      this.pension = info.pension;
      this.othersRetirmentBenefit = info.othersRetirmentBenefit;
      this.expectedMonthlyFixedTakeHome = info.expectedMonthlyFixedTakeHome;
      this.expectedYearlyFixedCTC = info.expectedYearlyFixedCTC;
      this.noticePeriodInDays = info.noticePeriodInDays;
      this.relocationWill = info.relocationWill;
      this.notRelocateDetails = info.notRelocateDetails;
      this.presentServiceBond = info.presentServiceBond;
      this.serviceBondDetails = info.serviceBondDetails;
      this.refferalsInfo = info.refferalsInfo;
      this.homeInternetConnectivity = info.homeInternetConnectivity;
      this.selfIntro = info.selfIntro;
      this.jobSuitableReason = info.jobSuitableReason;
      this.functionalAreaPreference = info.functionalAreaPreference;
      this.higherStudiesDetails = info.higherStudiesDetails;
      this.weeklyOffDaysDetails = info.weeklyOffDaysDetails;
      this.previouslyInterviedByUs = info.previouslyInterviedByUs;
      this.leaves = info.leaves;
      this.previouslyTested = info.previouslyTested;
      this.refferals = info.refferals;
      if (info.othersRetirmentBenefitDescription !== undefined) {
        this.othersRetirmentBenefitDescription = info.othersRetirmentBenefitDescription;
      }
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey);
      const doc = await type.get();
      const info = doc.data();
      this.type = info.postRecruitment;
      this.refKey = info.remunerationDetails;
    },
    addRefferalsRow() {
      const newRow = {
        name: '',
        code: '',
        designation: '',
        location: '',
        association: '',
      };
      this.refferals.push(newRow);
    },
    deleteRefferalsRow(index) {
      this.refferals.splice(index, 1);
    },
    addLeavesRow() {
      const newRow = {
        name: '',
        leavesPerYear: '',
        details: '',
      };
      this.leaves.push(newRow);
    },
    deleteLeavesRow(index) {
      this.leaves.splice(index, 1);
    },
    addPreviouslyTestedRow() {
      const newRow = {
        company: '',
        name: '',
        location: '',
        date: '',
      };
      this.previouslyTested.push(newRow);
    },
    deletePreviouslyTestedRow(index) {
      this.previouslyTested.splice(index, 1);
    },
    deleteExpRow(index) {
      this.experience.splice(index, 1);
    },
    addExpRow() {
      const newRow = {
        yearFrom: '',
        yearTo: '',
        specialization: '',
        institute: '',
        marks: '',
        id: this.experience.length,
      };
      this.experience.push(newRow);
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      // console.log('Dialog closed');
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
  },
  mounted() {
    this.addRefferalsRow();
    this.addLeavesRow();
    this.addPreviouslyTestedRow();
    this.getRefKey();
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getRemunerationDetails();
        }
      }
    },
  },
  computed: {
    dockey() {
      return this.$store.state.user.currentUser.email;
    },
    formattedDate() {
      return this.theDate ? moment(this.theDate).format('DD/MMM/YYYY') : moment(new Date()).format('DD/MMM/YYYY'); // Custom format
    },
    datePickerFormattedDate() {
      return this.theDate ? moment(this.theDate).format('DD-MMM-YYYY')
        : moment(new Date()).format('DD-MMM-YYYY'); // Date picker objects needs date in this particular format
    },
  },
};
</script>
