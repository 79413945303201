<template>
  <v-form
            ref="formFamilyDetails"
            v-model="validFamilyDetails"
            >
        <div class="container">
          <div>
            <label for="mother">Mother's details</label>
            <v-text-field
              name="motherName"
              v-model="motherName"
              label="Name"
              id="motherName"
              :rules="type ? nameRules : []"
              :class="{'required': type}"
            ></v-text-field>
            <vue-tel-input-vuetify
          mode="international"
              name="motherMobile"
              v-model="motherMobile"
              label="Phone/Mobile"
              id="motherMobile"
              :rules="type ? mobRules : []"
              :class="{'required': type}"
            ></vue-tel-input-vuetify>
          <v-dialog
          persistent
          v-model="motherModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
            name="motherDOB"
            v-model="motherDOB"
            label="Date of Birth"
            slot="activator"
            id="motherDOB"
            prepend-icon="mdi-calendar"
            v-on="on"
            :class="{'required': type}"
            :rules="type ? basicRules : []"
          ></v-text-field>
          </template>
          <v-date-picker v-model="motherDate" scrollable actions>
            <template v-scope="{ saveTheDate, cancelTheDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
            <v-text-field
              name="motherOccupation"
              v-model="motherOccupation"
              label="Occupation"
              id="motherOccupation"
              :rules="type ? basicRules : []"
              :class="{'required': type}"
            ></v-text-field>
            <v-text-field
              name="motherAnnualIncome"
              v-model="motherAnnualIncome"
              label="Annual Income(₹)"
              id="motherAnnualIncome"
              :rules="type ? basicRules : []"
              :class="{'required': type}"
            ></v-text-field>
          </div>
          <div>
            <label for="father">Father's details</label>
            <v-text-field
              name="fatherName"
              v-model="fatherName"
              label="Name"
              id="fatherName"
              :rules="type ? nameRules : []"
              :class="{'required': type}"
            ></v-text-field>
            <vue-tel-input-vuetify
          mode="international"
              name="fatherMobile"
              v-model="fatherMobile"
              label="Phone/Mobile"
              id="fatherMobile"
              :rules="type ? mobRules : []"
              :class="{'required': type}"
            ></vue-tel-input-vuetify>
                      <v-dialog
          persistent
          v-model="fatherModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
              name="fatherDOB"
              v-model="fatherDOB"
              label="Date of Birth"
              id="fatherDOB"
              prepend-icon="mdi-calendar"
              :rules="type ? basicRules : []"
              :class="{'required': type}"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="fatherDate" scrollable actions>
            <template v-scope="{ saveTheFatherDate, cancelTheFatherDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheFatherDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheFatherDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
            <v-text-field
              name="fatherOccupation"
              v-model="fatherOccupation"
              label="Occupation"
              id="fatherOccupation"
              :class="{'required': type}"
              :rules="type ? basicRules : []"
            ></v-text-field>
            <v-text-field
              name="fatherAnnualIncome"
              v-model="fatherAnnualIncome"
              label="Annual Income(₹)"
              id="fatherAnnualIncome"
              :class="{'required': type}"
              :rules="type ? basicRules : []"
            ></v-text-field>
          </div>
          <v-radio-group v-model="maritalStatus"
      required>
       <label>Marital Status<span class="red--text" v-if="type"><strong> * </strong></span></label>
        <v-radio
          label= "Married"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "Single"
          :value= "false"
        ></v-radio>
      </v-radio-group>
          <div v-if="this.maritalStatus" >
            <label for="spouse">Spouse's details</label>
            <v-text-field
              name="spouseName"
              v-model="spouseName"
              label="Name"
              id="spouseName"
              :rules="type ? nameRules : []"
              :class="{'required': type}"
            ></v-text-field>
            <vue-tel-input-vuetify
          mode="international"
              name="spouseMobile"
              v-model="spouseMobile"
              label="Phone/Mobile"
              id="spouseMobile"
              :rules="type ? mobRules : []"
              :class="{'required': type}"
            ></vue-tel-input-vuetify>
            <v-dialog
          persistent
          v-model="spouseModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
              name="spouseDOB"
              v-model="spouseDOB"
              label="Date of Birth"
              id="spouseDOB"
              :rules="type ? basicRules : []"
              prepend-icon="mdi-calendar"
              :class="{'required': type}"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="spouseDate" scrollable actions>
            <template v-scope="{ saveTheSpouseDate, cancelTheSpouseDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheSpouseDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheSpouseDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
            <v-text-field
              name="spouseOccupation"
              v-model="spouseOccupation"
              label="Occupation"
              id="spouseOccupation"
              :rules="type ? basicRules : []"
              :class="{'required': type}"
            ></v-text-field>
            <v-text-field
              name="spouseAnnualIncome"
              v-model="spouseAnnualIncome"
              label="Annual Income(₹)"
              id="spouseAnnualIncome"
              :rules="type ? basicRules : []"
              :class="{'required': type}"
            ></v-text-field>
          </div>
      <v-radio-group v-model="sibbling">
       <label>Do you have siblings<span class="red--text" v-if="type"><strong> * </strong></span></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
    </v-radio-group>
      <v-text-field v-if="sibbling" v-model="enteredBroCount" :class="{'required': type}"
      label="please specify number of Brothers" v-on:input="addRow"></v-text-field>
      <v-data-table
       v-if="sibbling"
      :headers="headers"
      :items="brothers"
      :server-items-length="brothers.length"
      :hide-default-footer="true"
      item-key = "props.index"
      hide-action>
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="type ? nameRules : []"></v-text-field>
      </template>
      <template v-slot:item.mobNum="props">
            <vue-tel-input-vuetify
          mode="international" v-model="props.item.mobNum" :rules="type ? mobRules : []"></vue-tel-input-vuetify>
      </template>
      <template v-slot:item.dob="props">
         <v-edit-dialog
            :return-value.sync="props.item.dob"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.dob != '' ? formatedDate(props.item.dob) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update DOB
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.dob"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.dob"
              no-title
              @change="$refs.menu.save()"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
      <template v-slot:item.occupation="props">
            <v-text-field v-model="props.item.occupation"  :rules="type ? basicRules : []"></v-text-field>
      </template>
      <template v-slot:item.annualIncome="props">
            <v-text-field v-model="props.item.annualIncome" :rules="type ? basicRules : []"></v-text-field>
      </template>
    </v-data-table>
    <v-text-field v-if="sibbling" v-model="enteredSisCount" :class="{'required': type}"
    label="please specify number of Sisters" v-on:input="addSisRow"></v-text-field>
      <v-data-table
       v-if="sibbling"
      :headers="headers"
      :items="sisters"
      :server-items-length="sisters.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="type ? nameRules : []"></v-text-field>
      </template>
      <template v-slot:item.mobNum="props">
            <vue-tel-input-vuetify
          mode="international" v-model="props.item.mobNum" :rules="type ? mobRules : []"></vue-tel-input-vuetify>
      </template>
      <template v-slot:item.dob="props">
            <v-edit-dialog
            :return-value.sync="props.item.dob"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.dob != '' ? formatedDate(props.item.dob) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update DOB
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.dob"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.dob"
              no-title
              @change="$refs.menu.save()"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
      <template v-slot:item.occupation="props">
            <v-text-field v-model="props.item.occupation" :rules="type ? basicRules : []"></v-text-field>
      </template>
      <template v-slot:item.annualIncome="props">
            <v-text-field v-model="props.item.annualIncome" :rules="type ? basicRules : []"></v-text-field>
      </template>
    </v-data-table>
    <v-text-field v-if="maritalStatus" v-model="enteredSonCount" :class="{'required': type}"
      label="please specify number of sons" v-on:input="addSonRow"></v-text-field>
      <v-data-table
       v-if="maritalStatus"
      :headers="headers"
      :items="sons"
      :server-items-length="sons.length"
      :hide-default-footer="true"
      item-key = "props.index"
      hide-action>
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="type ? nameRules : []"></v-text-field>
      </template>
      <template v-slot:item.mobNum="props">
            <vue-tel-input-vuetify
          mode="international" v-model="props.item.mobNum" :rules="type ? mobRules : []"></vue-tel-input-vuetify>
      </template>
      <template v-slot:item.dob="props">
         <v-edit-dialog
            :return-value.sync="props.item.dob"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.dob != '' ? formatedDate(props.item.dob) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update DOB
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.dob"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.dob"
              no-title
              @change="$refs.menu.save()"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
      <template v-slot:item.occupation="props">
            <v-text-field v-model="props.item.occupation"  :rules="type ? basicRules : []"></v-text-field>
      </template>
      <template v-slot:item.annualIncome="props">
            <v-text-field v-model="props.item.annualIncome" :rules="type ? basicRules : []"></v-text-field>
      </template>
    </v-data-table>
    <v-text-field v-if="maritalStatus" v-model="enteredDaughterCount" :class="{'required': type}"
    label="please specify number of daughters" v-on:input="addDaughterRow"></v-text-field>
      <v-data-table
       v-if="maritalStatus"
      :headers="headers"
      :items="daughters"
      :server-items-length="daughters.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.name="props">
            <v-text-field v-model="props.item.name" :rules="type ? nameRules : []"></v-text-field>
      </template>
      <template v-slot:item.mobNum="props">
            <vue-tel-input-vuetify
          mode="international" v-model="props.item.mobNum" :rules="type ? mobRules : []"></vue-tel-input-vuetify>
      </template>
      <template v-slot:item.dob="props">
            <v-edit-dialog
            :return-value.sync="props.item.dob"
            large
            persistent
            @cancel="cancel"
            @open="open"
            @close="close"
            @save="save"
          >
            <u>{{ props.item.dob != '' ? formatedDate(props.item.dob) : formatedDate(currentDate) }}</u>
            <template v-slot:input>
              <div class="mt-4 title">
                Update DOB
              </div>
              <v-menu
              ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
              <template v-slot:activator="slotData">
              <slot v-if="$scopedSlots.activator" :name="'activator'" v-bind="slotData" />
              <v-text-field
                v-model="props.item.dob"
                :rules="type ? basicRules : []"
                label="Edit"
                single-line
                readonly
                autofocus
                prepend-icon="mdi-calendar"
                v-else v-on="slotData.on"
              ></v-text-field>
              </template>
              <v-date-picker
              v-model="props.item.dob"
              no-title
              @change="$refs.menu.save()"
            ></v-date-picker>
          </v-menu>
            </template>
          </v-edit-dialog>
      </template>
      <template v-slot:item.occupation="props">
            <v-text-field v-model="props.item.occupation" :rules="type ? basicRules : []"></v-text-field>
      </template>
      <template v-slot:item.annualIncome="props">
            <v-text-field v-model="props.item.annualIncome" :rules="type ? basicRules : []"></v-text-field>
      </template>
    </v-data-table>
        <v-card>
        <v-card-actions class="justify-space-between">
            <v-btn class="mr-4" color="primary" @click="$emit('update:currentStep', currentStep -1);">
      Back
    </v-btn>
        <v-btn class="mr-4"
          color="primary"
          :disabled="loading" :loading="loading"
          @click="validateFamilyDetails"
        >
          Next
        </v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </div>
        </v-form>
</template>
<script>
import moment from 'moment';

export default {
  props: { currentStep: Number },
  data: () => ({
    isActive: false,
    theDate: new Date(),
    validRemunerationDetails: true,
    validExpereinceDetails: true,
    validFamilyDetails: true,
    maritalStatus: false,
    sibbling: false,
    name: '',
    loading: false,
    date: '',
    motherDate: '',
    fatherDate: '',
    spouseDate: '',
    menu2: false,
    motherModal: false,
    fatherModal: false,
    spouseModal: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    submittedId: '',
    brothers: [],
    sisters: [],
    sons: [],
    daughters: [],
    enteredDaughterCount: 0,
    enteredSonCount: 0,
    enteredBroCount: 0,
    enteredSisCount: 0,
    nextBarId: 1,
    headers: [
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: false,
      },
      { text: 'Phone num', value: 'mobNum' },
      { text: 'Date of Birth', value: 'dob' },
      { text: 'Occupation', value: 'occupation' },
      { text: 'Annual Income', value: 'annualIncome' },
    ],
    motherName: '',
    motherMobile: '',
    motherDOB: '',
    motherOccupation: '',
    motherAnnualIncome: '',
    fatherName: '',
    fatherMobile: '',
    fatherDOB: '',
    fatherOccupation: '',
    fatherAnnualIncome: '',
    spouseName: '',
    spouseMobile: '',
    spouseDOB: '',
    spouseOccupation: '',
    spouseAnnualIncome: '',
    snack: false,
    snackColor: '',
    snackText: '',
    currentDate: moment(new Date()),
    error: {
      status: false,
      message: '',
    },
    success: {
      status: false,
      message: '',
    },
    refKey: '',
    type: false,
  }),
  methods: {
    inputHandler(date) {
      let dt = date;
      if (typeof dt === 'string') {
        dt = moment(dt).toDate();
      }
      this.isActive = false;
      this.theDate = dt;
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      console.log('Dialog closed');
    },
    validateFamilyDetails() {
      if (this.$refs.formFamilyDetails.validate()) {
        this.submitFamilyDetails();
      } else {
        this.error.message = 'Please provide info to the mandatory fields';
        this.error.status = true;
      }
    },
    async singleEmit() {
      this.$emit('update:currentStep', this.currentStep + 1);
      if (this.refKey === '' || this.refKey === undefined) {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          familyDetails: this.submittedId,
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      } else {
        await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey).set({
          updatedStep: this.currentStep + 1,
        }, { merge: true });
      }
    },
    async submitFamilyDetails() {
      this.loading = true;
      try {
        if (this.refKey === '' || this.refKey === undefined) {
          const res = await this.firebase.firestore().collection('familyDetails').add({
            brothers: this.brothers,
            sisters: this.sisters,
            sons: this.sons,
            daughters: this.daughters,
            motherName: this.motherName,
            motherMobile: this.motherMobile,
            motherDOB: this.motherDOB,
            motherOccupation: this.motherOccupation,
            motherAnnualIncome: this.motherAnnualIncome,
            fatherName: this.fatherName,
            fatherMobile: this.fatherMobile,
            fatherDOB: this.fatherDOB,
            fatherOccupation: this.fatherOccupation,
            fatherAnnualIncome: this.fatherAnnualIncome,
            spouseName: this.spouseName,
            spouseMobile: this.spouseMobile,
            spouseDOB: this.spouseDOB,
            spouseOccupation: this.spouseOccupation,
            spouseAnnualIncome: this.spouseAnnualIncome,
            sibbling: this.sibbling,
            enteredDaughterCount: this.enteredDaughterCount,
            enteredSonCount: this.enteredSonCount,
            enteredBroCount: this.enteredBroCount,
            enteredSisCount: this.enteredBroCount,
            maritalStatus: this.maritalStatus,
          });
          this.submittedId = res.id;
          this.loading = false;
          this.singleEmit();
        } else {
          await this.firebase.firestore().collection('familyDetails').doc(this.refKey).update({
            brothers: this.brothers,
            sisters: this.sisters,
            sons: this.sons,
            daughters: this.daughters,
            motherName: this.motherName,
            motherMobile: this.motherMobile,
            motherDOB: this.motherDOB,
            motherOccupation: this.motherOccupation,
            motherAnnualIncome: this.motherAnnualIncome,
            fatherName: this.fatherName,
            fatherMobile: this.fatherMobile,
            fatherDOB: this.fatherDOB,
            fatherOccupation: this.fatherOccupation,
            fatherAnnualIncome: this.fatherAnnualIncome,
            spouseName: this.spouseName,
            spouseMobile: this.spouseMobile,
            spouseDOB: this.spouseDOB,
            spouseOccupation: this.spouseOccupation,
            spouseAnnualIncome: this.spouseAnnualIncome,
            sibbling: this.sibbling,
            enteredDaughterCount: this.enteredDaughterCount,
            enteredSonCount: this.enteredSonCount,
            enteredBroCount: this.enteredBroCount,
            enteredSisCount: this.enteredBroCount,
            maritalStatus: this.maritalStatus,
          });
          this.loading = false;
          this.singleEmit();
        }
      } catch (error) {
        if (error) {
          this.error.message = 'Something went wrong. Please try in some time.';
          this.error.status = true;
        }
      }
    },
    addRow() {
      this.brothers = [];
      let i = this.enteredBroCount;
      while (i > 0) {
        this.pushARow(i);
        i -= 1;
      }
    },
    addSisRow() {
      this.sisters = [];
      let i = this.enteredSisCount;
      while (i > 0) {
        this.pushASisRow(i);
        i -= 1;
      }
      // console.log('entered addRow');
    },
    addSonRow() {
      this.sons = [];
      let i = this.enteredSonCount;
      while (i > 0) {
        this.pushASonRow(i);
        i -= 1;
      }
      // console.log('entered addRow');
    },
    addDaughterRow() {
      this.daughters = [];
      let i = this.enteredDaughterCount;
      while (i > 0) {
        this.pushADaughterRow(i);
        i -= 1;
      }
      // console.log('entered addRow');
    },
    pushARow(i) {
      const newRow = {
        id: i - 1,
        name: '',
        mobNum: '',
        dob: '',
        occupation: '',
      };
      // console.log('entered addRow @', i);
      this.brothers.push(newRow);
      this.brothers.reverse();
    },
    pushASisRow(i) {
      const newRow = {
        id: i - 1,
        name: '',
        mobNum: '',
        dob: '',
        occupation: '',
      };
      this.sisters.push(newRow);
      this.sisters.reverse();
    },
    pushASonRow(i) {
      const newRow = {
        id: i,
        name: '',
        mobNum: '',
        dob: '',
        occupation: '',
      };
      this.sons.push(newRow);
      this.sons.reverse();
    },
    pushADaughterRow(i) {
      const newRow = {
        id: i,
        name: '',
        mobNum: '',
        dob: '',
        occupation: '',
      };
      this.daughters.push(newRow);
      this.daughters.reverse();
    },
    cancelTheDate() {
      this.motherModal = false;
    },
    cancelTheFatherDate() {
      this.fatherModal = false;
    },
    cancelTheSpouseDate() {
      this.spouseModal = false;
    },
    saveTheDate() {
      this.motherModal = false;
      this.motherDOB = this.formatedDate(this.motherDate);
    },
    saveTheFatherDate() {
      this.fatherModal = false;
      this.fatherDOB = this.formatedDate(this.fatherDate);
    },
    saveTheSpouseDate() {
      this.spouseModal = false;
      this.spouseDOB = this.formatedDate(this.spouseDate);
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    updatedTask() {
      // console.log('hi');
    },
    async getFamilyDetails() {
      // console.log('in family get');
      const familyDetails = await this.firebase.firestore().collection('familyDetails').doc(this.refKey);
      const doc = await familyDetails.get();
      const info = doc.data();
      this.brothers = info.brothers;
      this.sisters = info.sisters;
      this.sons = info.sons;
      this.daughters = info.daughters;
      this.motherName = info.motherName;
      this.motherMobile = info.motherMobile;
      this.motherDOB = info.motherDOB;
      this.motherOccupation = info.motherOccupation;
      this.motherAnnualIncome = info.motherAnnualIncome;
      this.fatherName = info.fatherName;
      this.fatherMobile = info.fatherMobile;
      this.fatherDOB = info.fatherDOB;
      this.fatherOccupation = info.fatherOccupation;
      this.fatherAnnualIncome = info.fatherAnnualIncome;
      this.spouseName = info.spouseName;
      this.spouseMobile = info.spouseMobile;
      this.spouseDOB = info.spouseDOB;
      this.spouseOccupation = info.spouseOccupation;
      this.spouseAnnualIncome = info.spouseAnnualIncome;
      this.sibbling = info.sibbling;
      this.maritalStatus = info.maritalStatus;
      this.enteredSonCount = info.enteredSonCount;
      this.enteredBroCount = info.enteredBroCount;
      this.enteredSisCount = info.enteredSisCount;
      this.enteredDaughterCount = info.enteredDaughterCount;
      this.maritalStatus = info.maritalStatus;
    },
    async getRefKey() {
      // console.log('getting type');
      const refResponse = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.dockey);
      const doc = await refResponse.get();
      const info = doc.data();
      this.type = info.postRecruitment;
      this.refKey = info.familyDetails;
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getFamilyDetails();
        }
      }
    },
  },
  computed: {
    dockey() {
      return this.$store.state.user.currentUser.email;
    },
    // formattedDate() {
    //   return this.theDate ? moment(this.theDate).format('DD/MMM/YYYY') : moment(new Date()).format('DD/MMM/YYYY'); // Custom format
    // },
    // datePickerFormattedDate() {
    //   return this.formatedDate(this.theDate); // Date picker objects needs date in this particular format
    // },
  },
  mounted() {
    this.getRefKey();
  },
};
</script>
